import {CommonModule} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {QuickMenuLoaderService} from '@application/data';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {Hotkeys} from '@configuration';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutes} from '@routing';
import {CkeditorModule} from '@ui/ckeditor';
import {FormModule} from '@ui/form';
import {MaterialModule} from '@ui/material';
import {PipesModule} from '@ui/pipes';
import {TableModule} from '@ui/table';
import {SecurityModule} from '@user/security';
import {VersionableUiModule} from '@versionable/ui';

import {AddButtonComponent} from './components/actions/add.component';
import {DeleteButtonComponent} from './components/actions/delete.component';
import {ActorDialogComponent} from './components/actor-dialog/actor-dialog.component';
import {ActorFormComponent} from './components/actor-form/actor-form.component';
import {ActorTableComponent} from './components/actor-table/actor-table.component';

@NgModule({
    imports: [
        CommonModule,
        CKEditorModule,
        CkeditorModule,
        FormModule,
        FormsModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule,
        SecurityModule,
        TableModule,
        TranslateModule.forChild(),
        VersionableUiModule
    ],
    declarations: [ActorDialogComponent, ActorFormComponent, ActorTableComponent, AddButtonComponent, DeleteButtonComponent],
    exports: [ActorDialogComponent, ActorFormComponent, ActorTableComponent, AddButtonComponent, DeleteButtonComponent]
})
export class ActorUiModule {
    constructor(
        private quickMenuService: QuickMenuLoaderService,
        private routes: AppRoutes,
        @Optional() @SkipSelf() parentModule?: ActorUiModule,
    ) {
        if (!parentModule) {
            this.quickMenuService.addItem({
                dialogComponent: ActorDialogComponent,
                label: 'ACTOR.ACTOR',
                dialogTitle: 'ACTOR.ADD_ACTOR',
                dialogHotkeys: Hotkeys.ADD_ACTOR,
                isSequence: true,
                detailsUrl: (id, isId) => this.routes.informationSystem.in(isId).actor.details(id),
                type: 'is',
            });
        }
    }
}
