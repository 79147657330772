import {IdType, extract} from '@axiocode/entity';
import {Versionable} from '@versionable/data';

export interface Actor extends Versionable {
    inherits?: Actor | null;
    formattedDescription?: string;
    isRole?: boolean;
    nbOpenDiscussions: number;
}

export const actorTrackBy = (index: number, item: Actor): IdType => item.id;

export const formatAPI = extract<Actor>({
    id: true,
    name: true,
    description: true,
    code: true,
    inherits: true,
    formattedDescription: false,
    isRole: true,
    application: extract({
        id: true,
    }),
    informationSystem: extract({
        id: true,
    }),
    typeClass: false,
    codeAndPrefix: false,
    entityType: false,
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false,
});
