import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {NonFunctionalRequirementProvider} from './services/non-functional-requirement.provider';
import {NonFunctionalRequirementStore, initializeDataLoader} from './services/non-functional-requirement.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class NonFunctionalRequirementDataModule {
    constructor(
        loader: DataLoaderService, provider: NonFunctionalRequirementProvider, store: NonFunctionalRequirementStore,
        @Optional() @SkipSelf() parentModule?: NonFunctionalRequirementDataModule
    ) {
        if (parentModule) {
            throw new Error('NonFunctionalRequirementDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
