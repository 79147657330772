import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {AppComponentProvider} from './services/app-component.provider';
import {AppComponentStore, initializeApplicationLoader} from './services/app-component.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class AppComponentDataModule {
    constructor(
        loader: DataLoaderService, provider: AppComponentProvider, store: AppComponentStore,
        @Optional() @SkipSelf() parentModule?: AppComponentDataModule
    ) {
        if (parentModule) {
            throw new Error('AppComponentDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeApplicationLoader(loader, provider, store)();
    }
}
