<h2 mat-dialog-title *ngIf="configuration.title">{{ configuration.title | translate }}</h2>

<mat-dialog-content class="modal" data-cy="mat-dialog-branch">
    <branch-form
        #branchForm
        [branch]="configuration.data"
        (submitted)="submitted($event)"
        (isInvalid)="invalid.set($event)"
        [(isSaving)]="saving">
    </branch-form>
</mat-dialog-content>

<mat-dialog-actions [attr.align]="'end'">
    <button
        mat-button
        data-cy="cancel-dialog-button"
        [disabled]="saving()"
        (click)="dialogDismissed.next()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>

    <ui-save-actions-button
        fixedSaveButton="save"
        [disabled]="invalid() || saving()"
        [dropdownDisplay]="false"
        (submitted)="handleValidationForm()">
    </ui-save-actions-button>

    @if (saving()) {
    <div class="saving-state">
        <span>{{ 'COMMON.SAVING' | translate }}</span>
        <mat-progress-spinner mode="indeterminate" [diameter]="20"></mat-progress-spinner>
    </div>
    }
</mat-dialog-actions>
