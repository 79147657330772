import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {EMPTY, Observable, Subject, catchError, concatMap, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {DataModelProvider} from './data-model.provider';
import {DataModelState} from '../models/data-model-state.interface';
import {DataModel} from '../models/data-model.model';

export function initializeDataLoader(service: DataLoaderService, provider: DataModelProvider, store: DataModelStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class DataModelStore extends EntityStore<DataModel, DataModelState> {
    #created = new Subject<EntitiesChange<DataModel>>();
    get created(): Observable<EntitiesChange<DataModel>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<DataModel>>();
    get updated(): Observable<EntitiesChange<DataModel>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<DataModel>>();
    get deleted(): Observable<EntitiesChange<DataModel>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    #provider = inject(DataModelProvider);

    // Selectors
    readonly selectDataModelsForCurrentIS$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.selectAll$,
        (IS, dataModels) => {
            if (IS) {
                return dataModels.filter(dataModel => dataModel.informationSystem.id === IS.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextDataModelCodeAvailable ?? 1
    );
    readonly selectPlantUmlAllDataModelsContent$ = this.select(state => state.plantUmlAllDataModelsContent);
    readonly selectAvailableFunctionalDomains$ = this.select(
        this.selectAll$,
        dataModels => Array.from(new Set(dataModels.flatMap(dataModel => dataModel.functionalDomains))).sort()
    );

    // Effects
    readonly loadPlantUmlDatamodelsContent = this.effect((trigger$: Observable<{dataModel?: DataModel, domains?: string[]}>) => trigger$.pipe(
        concatMap(data => this.#provider.findAllClassDiagramContent$(data.dataModel, data.domains).pipe(
            tap(svg => this.setPlantUmlAllDataModelsContent(svg)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    // Reducers
    readonly setPlantUmlAllDataModelsContent = this.updater((state, plantUmlAllDataModelsContent: string) => ({
        ...state,
        plantUmlAllDataModelsContent,
    }));

    /** @ignore */
    private subs = new SubSink();

    public override onSuccess(change: EntitiesChange<DataModel>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<DataModel, DataModelState> {
        return {
            storeName: 'DataModelStore',
            initialState: {ids: [], entities: {}, plantUmlAllDataModelsContent: undefined},
            selectId: dataModel => dataModel.id,
            sort: sortByCode,
        };
    }

    constructor(provider: DataModelProvider, private ISStore: InformationSystemStore) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }
}
