import {CommonModule} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {QuickMenuLoaderService} from '@application/data';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {Hotkeys} from '@configuration';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutes} from '@routing';
import {CkeditorModule} from '@ui/ckeditor';
import {FormModule} from '@ui/form';
import {MaterialModule} from '@ui/material';
import {PipesModule} from '@ui/pipes';
import {SvgDiagramModule} from '@ui/svg-diagram';
import {TableModule} from '@ui/table';
import {SecurityModule} from '@user/security';
import {VersionableUiModule} from '@versionable/ui';

import {AddButtonComponent} from './components/actions/add.component';
import {DeleteButtonComponent} from './components/actions/delete.component';
import {ClassDiagramComponent} from './components/class-diagram/class-diagram.component';
import {DataModelDialogComponent} from './components/data-model-dialog/data-model-dialog.component';
import {DataModelFormComponent} from './components/data-model-form/data-model-form.component';
import {DataModelTableComponent} from './components/data-model-table/data-model-table.component';

@NgModule({
    imports: [
        CommonModule,
        CKEditorModule,
        CkeditorModule,
        FormModule,
        FormsModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule,
        SecurityModule,
        SvgDiagramModule,
        TableModule,
        TranslateModule.forChild(),
        VersionableUiModule
    ],
    declarations: [
        AddButtonComponent,
        ClassDiagramComponent,
        DataModelDialogComponent,
        DataModelFormComponent,
        DataModelTableComponent,
        DeleteButtonComponent
    ],
    exports: [
        AddButtonComponent,
        ClassDiagramComponent,
        DataModelDialogComponent,
        DataModelFormComponent,
        DataModelTableComponent,
        DeleteButtonComponent
    ],
})
export class DataModelUiModule {
    constructor(
        private quickMenuService: QuickMenuLoaderService,
        private routes: AppRoutes,
        @Optional() @SkipSelf() parentModule?: DataModelUiModule,
    ) {
        if (!parentModule) {
            this.quickMenuService.addItem({
                dialogComponent: DataModelDialogComponent,
                label: 'DATA_MODEL.DATA_MODEL',
                dialogTitle: 'DATA_MODEL.ADD_DATA_MODEL',
                dialogHotkeys: Hotkeys.ADD_DATA_MODEL,
                isSequence: true,
                detailsUrl: (id, isId) => this.routes.informationSystem.in(isId).dataModel.details(id),
                type: 'is',
            });
        }
    }
}
