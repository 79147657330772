import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntityProvider, IdType} from '@axiocode/entity';
import {ApiConfig, ApiToken} from '@token';
import {Observable, Subscription, distinctUntilChanged, tap} from 'rxjs';

import {Comment} from '../models/comment.model';

@Injectable({providedIn: 'root'})
export class CommentProvider extends EntityProvider<Comment> implements OnDestroy {
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<Comment[]> {
        throw new Error('This method is not available on the API.');
    }

    override findOne$(id: IdType): Observable<never> {
        throw new Error('This method is not available on the API.');
    }

    override create$(data: Partial<Comment>): Observable<Comment> {
        return this._create$(`${this.config.apiBaseUrl}/api/comment`, data);
    }

    override update$(data: Partial<Comment>, method: 'patch' | 'put'): Observable<Comment> {
        return this._update$(`${this.config.apiBaseUrl}/api/comment/${data.id}`, data, 'patch');
    }

    override delete$(data: Comment): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/comment/${data.id}`);
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        applicationStore: ApplicationStore,
    ) {
        super();

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current application
            tap(() => this.resetCache()),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
