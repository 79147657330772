import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[formOnlyAlphaSpaceAccentNumeric]',
})
/**
 * Directive for a form field that allows only alphanumeric characters, space and accent characters.
 */
export class OnlyAlphaSpaceAccentDirective {
    /**
     * Regular expression (characters allowed).
     */
    regexStr = "^[a-zA-Z0-9 À-ú-%#']*$";

    /** @ignore */
    constructor(
        private el: ElementRef
        // eslint-disable-next-line no-empty-function
    ) {
    }
    /**
     * Listens to the key pressed by the user and allows only characters matching 'regexStr'.
     */
    @HostListener('keypress', ['$event']) onKeyPress(event: { key: string; }) {
        return new RegExp(this.regexStr).test(event.key);
    }
    /**
     * Listens to the field pasted by the user and use method validateFields().
     */
    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }
    /**
     * Removes the character which are not matching 'regexStr' and the blank space.
     */
    private validateFields(event: KeyboardEvent): void {
        setTimeout(() => {
            this.el.nativeElement.value = this.el.nativeElement.value
                .replace(/[^a-zA-Z0-9 À-ú-%#']/g, '');
            event.preventDefault();
        }, 100);
    }
}
