export const Hotkeys = {
    // Quick menu
    ADD_ACTOR: 'm>a',
    ADD_FEATURE: 'm>f',
    ADD_FUNCTIONAL_REQUIREMENT: 'm>r',
    ADD_NON_FUNCTIONAL_REQUIREMENT: 'm>n',
    ADD_NON_FUNCTIONAL_REQUIREMENT_PAGE: 'm>p',
    ADD_APPLICATION_COMPONENT: 'm>c',
    ADD_GLOSSARY_TERM: 'm>t',
    ADD_DATA_MODEL: 'm>m',
    ADD_USE_CASE: 'm>u',
    ADD_FORM: 'm>f>o',
    ADD_TABLE: 'm>l',

    // General shortcut
    CANCEL: 'meta.shift.z',
    EDIT: 'meta.shift.e',
    SAVE: 'meta.shift.s',
    SEARCH: 'shift.f',

    // Dialog escape
    ESC_DIALOG: 'esc',

    // PREVIEW
    BACK_PREVIEW: 'alt.b',
    ESC_PREVIEW: 'alt.x',
    PREVIEW_GO_DETAIL: 'alt.q',
    PREVIEW_GO_DETAIL_ON_ANOTHER_TAB: 'alt.s',
    PREVIEW_GO_DETAIL_AND_EDIT: 'alt.a',
    PREVIEW_GO_DETAIL_AND_EDIT_ON_ANOTHER_TAB: 'alt.z',

    // Dialog saving
    SAVE_AND_ADD_ANOTHER: 'meta.shift.a',
    SAVE_AND_SEE_DETAIL: 'meta.shift.v',
    SAVE_DIALOG: 'meta.shift.s',

    // IS
    IS_ADD_BRANCH: 'meta.b',

    // Use case
    SCENARIO_ADD_EXTENSION: 'meta.shift.x',
    SCENARIO_ADD_ALTERNATIVE: 'meta.shift.a',
    SCENARIO_ADD_ACTOR_STEP: 'meta.a',
    SCENARIO_ADD_RETURN_STEP: 'meta.z',
    SCENARIO_ADD_SYSTEM_STEP: 'meta.s',
    SCENARIO_SAVE: 'meta.shift.s',

    // Data Model
    ADD_ATTRIBUTE: 'meta.a',
    ADD_RELATIONSHIP: 'meta.r',

    // Form
    ADD_FORMFIELD: 'meta.a',

    // Table
    ADD_COLUMN: 'meta.a',

    // Layout
    // TOGGLE_DEPENDENCIES: 'shift.d',
    // TOGGLE_DISCUSSIONS: 'shift.c',
    // TOGGLE_NAVIGATION: 'shift.s',

    // SVG
    EXIT_FULLSCREEN: 'backspace',
} as const;

type ObjectValues<T> = T[keyof T];
export type Hotkey = ObjectValues<typeof Hotkeys>;
