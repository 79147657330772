import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {ActorProvider} from './services/actor.provider';
import {ActorStore, initializeDataLoader} from './services/actor.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class ActorDataModule {
    constructor(
        loader: DataLoaderService,
        provider: ActorProvider,
        store: ActorStore,
        @Optional() @SkipSelf() parentModule?: ActorDataModule
    ) {
        if (parentModule) {
            throw new Error('ActorDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
