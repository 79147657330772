import {HttpContext} from '@angular/common/http';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ConvertSnakeToCamelCase, EntityProvider, IdType} from '@axiocode/entity';
import {GenerateRequirementModel} from '@data-model/data';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {withCache} from '@ngneat/cashew';
import {ApiConfig, ApiToken} from '@token';
import {Observable, distinctUntilChanged, map, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {Feature} from '../models/feature.model';

export interface FeatureSvg {
    featureSvg: string;
    featureSvgWithoutCrud: string
}

@Injectable({providedIn: 'root'})
export class FeatureProvider extends EntityProvider<Feature> implements OnDestroy {
    /** @ignore */
    private informationSystem: InformationSystem | undefined = undefined;
    /** @ignore */
    private subs = new SubSink();

    override findAll$(): Observable<Feature[]> {
        if (undefined === this.informationSystem) {
            throw new Error('Current information system is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystem/${this.informationSystem.id}/features`);
    }

    override findOne$(id: IdType): Observable<Feature> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/feature/${id}`);
    }

    override create$(data: Partial<Feature>): Observable<Feature> {
        return this._create$(`${this.config.apiBaseUrl}/api/feature`, data);
    }

    override update$(data: Partial<Feature>, method: 'patch' | 'put'): Observable<Feature> {
        return this._update$(`${this.config.apiBaseUrl}/api/feature/${data.id}`, data, method);
    }

    override delete$(data: Feature): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/feature/${data.id}`);
    }

    findFeatureUML$(feature: Feature): Observable<FeatureSvg> {
        return this.http.get<FeatureSvg>(`${this.config.apiBaseUrl}/api/application/${feature.application.id}/umldiagram/features/?feature=${feature.id}`, {
            context: withCache({
                cache: true,
                bucket: this.cacheBucket,
                ttl: this.ttl,
            }).set(ConvertSnakeToCamelCase, true),
            withCredentials: false
        }).pipe(
            map(url => ({
                featureSvg: url.featureSvg,
                featureSvgWithoutCrud: url.featureSvgWithoutCrud
            }))
        );
    }

    createFeatureCrudFromDataModel(data: GenerateRequirementModel): Observable<Feature> {
        return this.http.post<Feature>(`${this.config.apiBaseUrl}/api/usecase/generate/${data.dataModel.id}`, data, {
            context: new HttpContext().set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion)
        });
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        ISStore: InformationSystemStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subs.sink = ISStore.selectSelectedEntity$.pipe(
            // Filter out if the IS is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current IS
            tap(() => this.resetCache()),
            tap(IS => this.informationSystem = IS),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
