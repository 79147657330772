import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';
import {Observable, Subject, tap} from 'rxjs';

import {AppComponentProvider} from './app-component.provider';
import {AppComponentState} from '../models/app-component-state.interface';
import {AppComponent} from '../models/app-component.model';

export function initializeApplicationLoader(service: DataLoaderService, provider: AppComponentProvider, store: AppComponentStore) {
    return () => service.registerLoader(() => provider.findAll$().pipe(tap(data => store.setMany(data))));
}

@Injectable({providedIn: 'root'})
export class AppComponentStore extends EntityStore<AppComponent, AppComponentState> {
    #created = new Subject<EntitiesChange<AppComponent>>();
    get created(): Observable<EntitiesChange<AppComponent>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<AppComponent>>();
    get updated(): Observable<EntitiesChange<AppComponent>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<AppComponent>>();
    get deleted(): Observable<EntitiesChange<AppComponent>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectAppComponentsForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, appComponents) => {
            if (application) {
                return appComponents.filter(appComponent => appComponent.application?.id === application.id);
            }

            return [];
        }
    );

    public override onSuccess(change: EntitiesChange<AppComponent>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<AppComponent, AppComponentState> {
        return {
            storeName: 'AppComponentStore',
            initialState: {ids: [], entities: {}},
            selectId: appComponent => appComponent.id,
        };
    }

    constructor(provider: AppComponentProvider, private applicationStore: ApplicationStore) {
        super(provider);
    }
}
