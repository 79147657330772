import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {DataModelProvider} from './services/data-model.provider';
import {DataModelStore, initializeDataLoader} from './services/data-model.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class DataModelDataModule {
    constructor(
        loader: DataLoaderService, provider: DataModelProvider, store: DataModelStore,
        @Optional() @SkipSelf() parentModule?: DataModelDataModule
    ) {
        if (parentModule) {
            throw new Error('DataModelDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
