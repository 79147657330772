import {Injectable} from '@angular/core';
import {RouteBuilder} from '@axiocode/routing';

import {ApplicationSelectionRoutes} from './routes/application-routes.service';
import {AuthRoutes} from './routes/auth-routes.service';
import {InformationSystemRoutes} from './routes/information-system-routes.service';
import {OrganizationRoutes} from './routes/organization-routes.service';
import {UserRoutes} from './routes/user-routes.service';

@Injectable({providedIn: 'any'})
export class AppRoutes extends RouteBuilder {
    auth = this.childRoutes('auth', AuthRoutes);
    organization = this.childRoutes('organization', OrganizationRoutes);
    informationSystem = this.childRoutes('is', InformationSystemRoutes);
    application = this.childRoutes('application', ApplicationSelectionRoutes);
    user = this.childRoutes('user', UserRoutes);

    error404 = this.url('not-found');

    login = (returnUrl?: string) => this.urlFromCommands(['login'], {
        ...returnUrl ? {queryParams: {returnUrl}} : {}
    });
}
