import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, inject} from '@angular/core';
import {DataModel, DataModelLocalStore} from '@data-model/data';
import {DialogComponent} from '@ui/dialog';

import {DataModelFormComponent} from '../data-model-form/data-model-form.component';

@Component({
    selector: 'data-model-dialog',
    templateUrl: './data-model-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DataModelLocalStore]
})
export class DataModelDialogComponent extends DialogComponent implements AfterViewInit {

    @ViewChild(DataModelFormComponent) dataModel?: DataModelFormComponent;

    #localStore = inject(DataModelLocalStore);

    redirect = false;
    another = false;
    saveState$ = this.#localStore.selectSaveState$;

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngAfterViewInit(): void {
        super.addActionShortcuts(undefined === this.configuration.data.editDataModel);
        this.#localStore.setCurrentDataModel(this.configuration.data?.editDataModel);
    }

    submitted(next: DataModel): void {
        if (!this.another) {
            this.redirect ? this.dialogAcceptedRedirect.next(next) : this.dialogAccepted.next(next);
        } else {
            this.dialogAcceptedAndAdd.next(next);
        }
        super.removeActionShortcuts();
    }

    dismissDialog(): void {
        this.dialogDismissed.next();
    }

    override handleValidationForm(redirect: boolean, another: boolean): void {
        this.redirect = redirect;
        this.another = another;
        this.dataModel?.validate();
    }
}
