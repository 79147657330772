import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {catchError, first, map, of, switchMap, tap} from 'rxjs';

import {AuthStore} from '../services/auth.store';
import {AuthModuleToken, UserProviderToken} from '../token';

/**
 * Checks if the user is logged in or if he can be logged in with a JWT token.
 */
export const authGuard: CanActivateFn = (route, state) => {
    const config = inject(AuthModuleToken);
    const router = inject(Router);
    const store = inject(AuthStore);
    const userProvider = inject(UserProviderToken);

    return inject(AuthStore).selectIsLoggedIn$.pipe(
        first(), // Otherwise will be called every time isLoggedIn$ changes
        switchMap(isLoggedIn => {
            if (isLoggedIn) {
                return of(true);
            }

            return userProvider.fetchCurrentUserInformation$().pipe(
                tap(user => store.onLoggedIn(user)),
                map(() => true),
                catchError(() => of(router.parseUrl(config.loginUrl)))
            );
        })
    );

};
