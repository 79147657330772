import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DiscussionUiModule} from '@discussion/ui';
import {TranslateModule} from '@ngx-translate/core';
import {BreadcrumbModule} from '@ui/breadcrumb';
import {DrawerModule} from '@ui/drawer';
import {FormModule} from '@ui/form';
import {HotkeysModule} from '@ui/hotkeys';
import {LoaderModule} from '@ui/loader';
import {MaterialModule} from '@ui/material';
import {NavigationPlanModule} from '@ui/navigation-plan';
import {PipesModule} from '@ui/pipes';
import {TableModule} from '@ui/table';
import {SecurityModule} from '@user/security';

import {DependencyLinkComponent} from './components/dependency-link/dependency-link.component';
import {VersionableDetailComponent} from './components/versionable-detail/versionable-detail.component';
import {VersionableLastEditedTableComponent} from './components/versionable-last-edited-table/versionable-last-edited-table.component';
import {VersionableMenuItemComponent} from './components/versionable-menu-item/versionable-menu-item.component';
import {CodePipe} from './pipes/code.pipe';
import {VersionableTranslationKeyPipe} from './pipes/versionable-translation-key.pipe';

@NgModule({
    imports: [
        BreadcrumbModule,
        CommonModule,
        DiscussionUiModule,
        DrawerModule,
        FormModule,
        FormsModule,
        HotkeysModule,
        LoaderModule,
        MaterialModule,
        NavigationPlanModule,
        PipesModule,
        ReactiveFormsModule,
        SecurityModule,
        TableModule,
        TranslateModule.forChild(),
    ],
    declarations: [CodePipe, DependencyLinkComponent, VersionableDetailComponent, VersionableLastEditedTableComponent, VersionableMenuItemComponent, VersionableTranslationKeyPipe],
    exports: [CodePipe, DependencyLinkComponent, VersionableDetailComponent, VersionableLastEditedTableComponent, VersionableMenuItemComponent, VersionableTranslationKeyPipe],
})
export class VersionableUiModule {}
