import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {tapResponse} from '@ngrx/operators';
import {Observable, Subject, exhaustMap, iif, of, switchMap, take, tap} from 'rxjs';

import {InformationSystemProvider} from './information-system.provider';
import {InformationSystemState} from '../models/information-system-state.interface';
import {InformationSystem} from '../models/information-system.model';

@Injectable({providedIn: 'root'})
export class InformationSystemStore extends EntityStore<InformationSystem, InformationSystemState> {

    #created = new Subject<EntitiesChange<InformationSystem>>();
    get created(): Observable<EntitiesChange<InformationSystem>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<InformationSystem>>();
    get updated(): Observable<EntitiesChange<InformationSystem>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<InformationSystem>>();
    get deleted(): Observable<EntitiesChange<InformationSystem>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Effects
    readonly refreshCurrentIS$ = this.selectSelectedEntity$.pipe(
        take(1),
        tap(() => this.provider.resetCache()),
        switchMap(IS => iif(
            () => undefined !== IS,
            this.provider.findOne$(IS?.id as IdType).pipe(tap(IS => this.upsertOne(IS))),
            of(undefined)
        )),
    );
    readonly refreshAll = this.effect<void>(trigger$ => trigger$.pipe(
        exhaustMap(() => this.provider.findAll$().pipe(
            tapResponse(
                entities => {
                    this.upsertMany(entities);
                    this.onSuccess({type: 'get', entities});
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        this.onError(error);
                    }
                }
            )
        ))
    ));

    // Selectors
    readonly selectAllByOrganization$ = (organization: {id: IdType}) => this.select(
        this.selectAll$,
        informationSystems => informationSystems.filter(informationSystem => informationSystem.organization?.id === organization.id)
    );

    public override onSuccess(change: EntitiesChange<InformationSystem>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }


    protected override getEntityAdapter(): EntityAdapter<InformationSystem, InformationSystemState> {
        return {
            storeName: 'InformationSystemStore',
            initialState: {ids: [], entities: {}},
            selectId: informationSystem => informationSystem.id,
        };
    }

    constructor(provider: InformationSystemProvider) {
        super(provider);
    }
}
