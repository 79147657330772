import {Directive, ElementRef, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formControlName]'
})
export class NativeElementFormGroupInjectorDirective implements OnInit {
    // eslint-disable-next-line no-empty-function
    constructor (private el: ElementRef, private control : NgControl) {}

    ngOnInit() {
        (this.control.control as any).nativeElement = this.el.nativeElement;
    }
}
