import {Component, Input, OnInit, inject} from '@angular/core';
import {UserPreferencesService, UserPreferencesTypes} from '@configuration';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'loader-application-loader',
    templateUrl: './application-loader.component.html',
    styleUrls: ['./application-loader.component.scss'],
})
export class ApplicationLoaderComponent implements OnInit {
    /** Progress value, percentage between 0 and 100 */
    @Input() value = 0;

    #preferences = inject(UserPreferencesService);
    #translator = inject(TranslateService);
    displayTips = false;
    tip: string | null = null;

    /** @ignore */
    ngOnInit(): void {
        this.displayTips = this.#preferences.get(UserPreferencesTypes.SETTINGS_DISCOVERY_MODE, false) as boolean;

        // We're using the translator to get all the tips, so that we only need to change the translation file to add/remove tips
        this.#translator.get('DISCOVERY.TIPS').subscribe(tips => {
            const tipCount = tips.length;
            const tipNumber = Math.floor(Math.random() * tipCount);
            if (tipCount > 0) {
                this.tip = tips[tipNumber];
            }
        });
    }
}
