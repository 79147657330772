import {HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {camelize, decamelize} from '@ridi/object-case-converter';
import {Observable, map} from 'rxjs';

export const ConvertSnakeToCamelCase = new HttpContextToken<boolean>(() => false);

@Injectable({providedIn: 'root'})
export class SnakeToCamelInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.context.get(ConvertSnakeToCamelCase)) {
            return next.handle(req.clone({body: decamelize(req.body, {recursive: true})})).pipe(
                map(response => {
                    if (response instanceof HttpResponse) {
                        return response.clone({body: camelize(response.body, {recursive: true})});
                    }

                    return response;
                })
            );
        }

        return next.handle(req.clone());

    }

}