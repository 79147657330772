import {InjectionToken} from '@angular/core';

import {UserProviderInterface} from './services/user-provider.interface';

/**
 * Configuration object for AuthModule.
 */
export interface AuthModuleConfig {
    /** Login page url */
    loginUrl: string;
    /** The auth service base URL, without the trailing '/' */
    authServiceBaseUrl: string;
    /** The API base URL, without the trailing '/' */
    apiBaseUrl: string;
    /** The default URL to go to after a successful login */
    redirectAfterLogin: string;
    /** The Google id for google authentication */
    googleClientId?: string;
    /** Set to true to enable the Google authentication button. Also requires "googleClientId" */
    enableGoogleAuth?: boolean;
    /** If set to true, the refresh token will always be used */
    alwaysRememberMe?: boolean;
}

/** @ignore */
export const AuthModuleToken = new InjectionToken<AuthModuleConfig>('AuthModuleConfig');
export const UserProviderToken = new InjectionToken<UserProviderInterface>('UserProviderInterface');
