<h2 mat-dialog-title *ngIf="configuration.title">{{ configuration.title | translate }}</h2>

<mat-dialog-content class="modal" data-cy="mat-dialog-data-model">
    <data-model-form
        #dataModelForm
        [informationSystem]="configuration.data.informationSystem"
        [dataModel]="configuration.data.editDataModel"
        (submitted)="submitted($event)">
    </data-model-form>
</mat-dialog-content>

<mat-dialog-actions [attr.align]="'end'">
    <button
        *ngIf="(saveState$ | async) === false"
        mat-button
        data-cy="cancel-dialog-button"
        (click)="dismissDialog()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>

    <ui-save-actions-button
        *ngIf="(saveState$ | async) === false"
        [disabled]="(dataModelForm ? (dataModelForm.isInvalid | async) : true) ?? false"
        [dropdownDisplay]="!dataModelForm?.dataModel?.id"
        (submitted)="this.handleValidationForm($event.redirect, $event.another)">
    </ui-save-actions-button>

    <div class="saving-state" *ngIf="saveState$ | async">
        <span>{{ 'COMMON.SAVING' | translate }}</span>
        <mat-progress-spinner mode="indeterminate" [diameter]="20"></mat-progress-spinner>
    </div>
</mat-dialog-actions>
