import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {Branch} from '../models/branch.model';

@Injectable({providedIn: 'root'})
export class BranchSwitcherService {
    #branchChanged = new Subject<Branch>();
    get branchChanged$() {
        return this.#branchChanged.asObservable();
    }

    switchBranch(branch: Branch) {
        window.localStorage.setItem('currentBranch', JSON.stringify(branch));
        this.#branchChanged.next(branch);
    }

    constructor() {
        try {
            const currentBranch = JSON.parse(window.localStorage.getItem('currentBranch') ?? '');
            if (currentBranch) {
                this.#branchChanged.next(currentBranch);
            }
        } catch (e) {
            // avoid failed test
        }
    }
}
