import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '@ui/notification';
import {Observable, filter, map, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {PasswordResetStore} from '../../services/password-reset.store';
import {AuthModuleConfig, AuthModuleToken} from '../../token';

@Component({
    selector: 'auth-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PasswordResetStore],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    token$: Observable<string | undefined> = this.passwordResetStore.selectToken$;
    requesting$: Observable<boolean> = this.passwordResetStore.selectRequesting$;
    error$: Observable<string | undefined> = this.passwordResetStore.selectError$.pipe(
        filter(error => undefined !== error),
        tap(error => this.notificationService.notifyError(error ?? ''))
    );
    success$: Observable<boolean> = this.passwordResetStore.selectRequestSuccess$.pipe(
        filter(success => true === success),
        tap(() =>
            this.notificationService
                .notifySuccess('SUCCESS.PASSWORD_CHANGE')
                .then(this.notificationService.redirectAfterDismiss(this.config.loginUrl))
        )
    );

    private subs: SubSink = new SubSink();

    constructor(
        @Inject(AuthModuleToken) public readonly config: AuthModuleConfig,
        private readonly passwordResetStore: PasswordResetStore,
        private route: ActivatedRoute,
        private readonly notificationService: NotificationService,
    ) {
        this.subs.sink = this.success$.subscribe();
    }

    ngOnInit(): void {
        // Active extraction of the token from the URL
        this.subs.sink = this.route.paramMap
            .pipe(
                filter(map => map.has('token')),
                map(map => map.get('token') ?? ''),
                tap(token => this.passwordResetStore.setToken(token))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public passwordReset(newPassword: string): void {
        this.passwordResetStore.sendPasswordResetRequest(newPassword);
    }
}
