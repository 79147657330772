import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {DataLoaderService} from '@information-system/data';

import {DependencyProvider} from './services/dependency.provider';
import {DependencyStore, initializeDataLoader} from './services/dependency.store';

@NgModule({
    imports: [
        HttpClientModule,
    ],
})
export class VersionableDataModule {
    constructor(
        loader: DataLoaderService, provider: DependencyProvider, store: DependencyStore,
        @Optional() @SkipSelf() parentModule?: VersionableDataModule
    ) {
        if (parentModule) {
            throw new Error('VersionableDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
