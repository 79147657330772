import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from '@ui/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {NativeElementFormGroupInjectorDirective} from './directives/native-element-form-group-injector.directive';
import {OnlyAlphaSpaceAccentDirective} from './directives/only-alpha-space-and-accent.directive';
import {SelectSearchComponent} from './select-search/select-search.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
    ],
    declarations: [SelectSearchComponent, NativeElementFormGroupInjectorDirective, OnlyAlphaSpaceAccentDirective],
    exports: [SelectSearchComponent, NativeElementFormGroupInjectorDirective, NgxMatSelectSearchModule, OnlyAlphaSpaceAccentDirective],
})
export class FormModule {}
