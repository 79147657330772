import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IdType} from '@axiocode/entity';
import {Organization} from '@organization/data';
import {OrganizationStore} from '@organization/data';
import {AppRoutes} from '@routing';
import {DrawerService} from '@ui/drawer';
import {PermissionService, keepOnlyOrganizationsWithPermission} from '@user/security';
import {modelsEqualityById} from '@utils';
import {map} from 'rxjs';

@Component({
    selector: 'toolbar-organization-switcher',
    template: `
    <div class="row">
        <div class="switcher-select" *ngIf="allOrganizations$ | async as organizations">
            <mat-form-field>
                <mat-label>{{ 'ORGANIZATION.ORGANIZATION' | translate }}</mat-label>
                <mat-select [compareWith]="compare" [(value)]="currentOrganization">
                    <mat-option
                        *ngFor="let organization of organizations"
                        (click)="changeOrganization(organization)"
                        [value]="organization">
                        {{ organization.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSwitcherComponent {

    @Input() currentOrganization?: Organization;

    allOrganizations$ = this.organizationStore.selectAll$.pipe(
        // Check if the user has the right to read the organization, refreshed when user information changes
        keepOnlyOrganizationsWithPermission('read', this.permissionService),
        map(organizations => organizations.sort((a, b) => a.name.localeCompare(b.name))),
    );

    compare = modelsEqualityById;

    constructor(
        public routes: AppRoutes,
        private organizationStore: OrganizationStore,
        private drawerService: DrawerService,
        private permissionService: PermissionService,
    ) {
        this.organizationStore.findAll();
    }

    changeOrganization(organization: {id: IdType}): void {
        this.drawerService.closeDrawer().then(() => this.routes.organization.details(organization.id).navigate());
    }
}

