import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {BranchStore} from '@branch/data';
import {ComponentStore} from '@ngrx/component-store';
import {PermissionService} from '@user/security';
import {VersionableState} from '@versionable/data';
import {distinctUntilChanged, map, of, tap} from 'rxjs';

import {GlossaryTermStore} from './glossary-term.store';
import {GlossaryTerm} from '../models/glossary-term.model';

export interface GlossaryTermLocalState {
    currentGlossaryTerm?: GlossaryTerm;
    versionableState: VersionableState;
    force: boolean;
    formSaveState: boolean;
}

const defaultState: GlossaryTermLocalState = {
    versionableState: 'view',
    force: false, // force edition mode (from drawer)
    currentGlossaryTerm: undefined,
    formSaveState: false,
};

@Injectable()
export class GlossaryTermLocalStore extends ComponentStore<GlossaryTermLocalState> {
    // Synchronous operations. Avoid using them if you can.
    get currentGlossaryTerm(): GlossaryTerm | undefined {
        return this.get().currentGlossaryTerm;
    }

    // Selectors
    private readonly selectCurrent$ = this.select(state => state.currentGlossaryTerm);
    readonly selectCurrentGlossaryTerm$ = this.select(
        this.store.selectTermsForCurrentIS$,
        this.selectCurrent$,
        // we map the "currentGlossaryTerm" from the actual GlossaryTermStore so that we have the latest available data
        (glossaryTerms, current) => glossaryTerms.find(glossaryTerm => glossaryTerm.id === current?.id)
    );
    readonly selectPreview$ = this.route.parent ? this.route.parent.data.pipe(map(data => data['preview'] as boolean ?? false)) : of(false);
    readonly selectVersionableState$ = this.select(state => state.versionableState);
    readonly selectSaveState$ = this.select(state => state.formSaveState);

    // Updaters
    readonly setCurrentGlossaryTerm = this.updater((state, glossaryTerm: GlossaryTerm) => ({
        ...state,
        currentGlossaryTerm: glossaryTerm,
    }));
    readonly changeVersionableState = this.updater((state, vState: VersionableState) => ({
        ...state,
        versionableState: vState
    }));
    readonly changeSaveState = this.updater((state, sState: boolean) => ({
        ...state,
        formSaveState: sState
    }));
    readonly setForce = this.updater((state, force: boolean) => ({
        ...state,
        force,
    }));

    constructor(
        private route: ActivatedRoute,
        private store: GlossaryTermStore,
        private branchStore: BranchStore,
        private permissionService: PermissionService,
    ) {
        super(defaultState);

        // Refreshes the store data every time the URL changes
        this.route.data.pipe(
            takeUntilDestroyed(),
            tap(data => {
                if (data['editMode']) {
                    this.changeVersionableState('edition');
                }
                if (data['force']) {
                    this.setForce(true);
                } else {
                    this.setForce(false);
                }
                this.setCurrentGlossaryTerm(data['glossaryTerm']);
            })
        ).subscribe();

        // No edition on main branch (but exception for super admin)
        this.branchStore.selectIsMainBranch$.pipe(
            takeUntilDestroyed(),
            distinctUntilChanged(),
            tap(isMain => isMain && !this.permissionService.isSuperAdmin() ? this.changeVersionableState('view') : null),
        ).subscribe();
    }
}
