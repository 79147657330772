import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ArrayAccessorService {

    static access(from: any, pathToData: string): any {
        const splits = pathToData.split('.');
        for (const path of splits) {
            if (from[path]) {
                from = from[path];
            } else {
                return undefined;
            }
        }

        return from;
    }

    access(from: any, pathToData: string): any {
        return ArrayAccessorService.access(from, pathToData);
    }

}
