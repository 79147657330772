import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, inject} from '@angular/core';
import {GlossaryTerm, GlossaryTermLocalStore} from '@glossary-term/data';
import {DialogComponent} from '@ui/dialog';

import {GlossaryTermFormComponent} from '../glossary-term-form/glossary-term-form.component';

@Component({
    selector: 'glossary-term-dialog',
    templateUrl: './glossary-term-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GlossaryTermLocalStore]
})
export class GlossaryTermDialogComponent extends DialogComponent implements AfterViewInit {

    @ViewChild(GlossaryTermFormComponent) glossaryTermForm?: GlossaryTermFormComponent;

    #localStore = inject(GlossaryTermLocalStore);

    redirect = false;
    another = false;
    saveState$ = this.#localStore.selectSaveState$;

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngAfterViewInit(): void {
        super.addActionShortcuts(undefined === this.configuration.data.editGlossaryTerm);
        this.#localStore.setCurrentGlossaryTerm(this.configuration.data?.editGlossaryTerm);
    }

    submitted(next: GlossaryTerm): void {
        if (!this.another) {
            this.redirect ? this.dialogAcceptedRedirect.next(next) : this.dialogAccepted.next(next);
        } else {
            this.dialogAcceptedAndAdd.next(next);
        }
        super.removeActionShortcuts();
    }

    dismissDialog(): void {
        this.dialogDismissed.next();
    }

    public override handleValidationForm(redirect: boolean, another: boolean): void {
        this.redirect = redirect;
        this.another = another;
        this.glossaryTermForm?.validate();
    }
}
