export enum UserPreferencesTypes {
    // Expansion preferences
    EXPANSION_CLASS_DIAGRAM = 'expansionClassDiagram',
    EXPANSION_MANAGED_TABLE_FORM = 'expansionManagedTableForm',
    EXPANSION_MANAGED_TABLE_FUNCTIONAL_REQUIREMENT = 'expansionManagedTableFunctionalRequirement',
    EXPANSION_MANAGED_TABLE_PAGE = 'expansionManagedTablePage',
    EXPANSION_MANAGED_TABLE_TABLE = 'expansionManagedTableTable',
    EXPANSION_MANAGED_TABLE_USE_CASE = 'expansionManagedTableUseCase',
    EXPANSION_USE_CASE_BY_FEATURE_DIAGRAM = 'expansionUseCaseByFeatureDiagram',
    EXPANSION_USE_CASE_DIAGRAM = 'expansionUseCaseDiagram',
    EXPANSION_SIDENAV = 'expandNavigationSidebar',

    // Pagination preferences
    PAGINATION_ACTOR_TABLE = 'paginationActor',
    PAGINATION_APPLICATION_TABLE = 'paginationApplication',
    PAGINATION_DATA_MODEL_TABLE = 'paginationDataModel',
    PAGINATION_FEATURE_TABLE = 'paginationFeature',
    PAGINATION_FORM_TABLE = 'paginationForm',
    PAGINATION_FUNCTIONAL_REQUIREMENT_TABLE = 'paginationFunctionalRequirement',
    PAGINATION_GLOSSARY_TABLE = 'paginationGlossary',
    PAGINATION_INFORMATION_SYSTEM_TABLE = 'paginationInformationSystem',
    PAGINATION_NON_FUNCTIONAL_REQUIREMENT_TABLE = 'paginationNonFunctionalRequirement',
    PAGINATION_ORGANIZATION_TABLE = 'paginationOrganization',
    PAGINATION_PAGE_TABLE = 'paginationPage',
    PAGINATION_TABLE_TABLE = 'paginationTable',
    PAGINATION_USE_CASE_TABLE = 'paginationUseCase',
    PAGINATION_USER_TABLE = 'paginationUser',

    // Sorting preferences
    SORTING_ACTOR_TABLE = 'sortActor',
    SORTING_APPLICATION_TABLE = 'sortApplication',
    SORTING_DATA_MODEL_TABLE = 'sortDataModel',
    SORTING_FEATURE_TABLE = 'sortFeature',
    SORTING_FORM_TABLE = 'sortForm',
    SORTING_FUNCTIONAL_REQUIREMENT_TABLE = 'sortFunctionalRequirement',
    SORTING_GLOSSARY_TABLE = 'sortGlossary',
    SORTING_INFORMATION_SYSTEM_TABLE = 'sortInformationSystem',
    SORTING_NON_FUNCTIONAL_REQUIREMENT_TABLE = 'sortNonFunctionalRequirement',
    SORTING_ORGANIZATION_TABLE = 'sortOrganization',
    SORTING_PAGE_TABLE = 'sortPage',
    SORTING_TABLE_TABLE = 'sortTable',
    SORTING_USE_CASE_TABLE = 'sortUseCases',
    SORTING_USER_TABLE = 'sortUser',

    // Open detail from preview in new tab preferences
    // OPEN_DETAIL_IN_NEW_TAB = 'openDetailInNewTab',

    // Save preferences
    SAVE = 'save',

    PDF_WITH_DEPENDENCIES = 'pdfWithDependencies',
    FEATURE_DIAGRAM_SWITCH_CRUD = 'featureDiagramSwitchCrud',

    // Settings
    SETTINGS_DISCOVERY_MODE = 'settingsDiscoveryMode',
    // Saved filters
    FILTERS_USE_CASE_DIAGRAM = 'filtersUseCaseDiagram',
}
