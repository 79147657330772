import {Inject, Injectable} from '@angular/core';
import {EntityProvider, IdType} from '@axiocode/entity';
import {ApiConfig, ApiToken} from '@token';
import {Observable} from 'rxjs';

import {InformationSystem} from '../models/information-system.model';

@Injectable({providedIn: 'root'})
export class InformationSystemProvider extends EntityProvider<InformationSystem> {
    override findAll$(): Observable<InformationSystem[]> {
        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystems`);
    }

    override findOne$(id: IdType): Observable<InformationSystem> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/informationsystem/${id}`);
    }

    override create$(data: Partial<InformationSystem>): Observable<InformationSystem> {
        return this._create$(`${this.config.apiBaseUrl}/api/informationsystem`, data);
    }

    override update$(data: Partial<InformationSystem>): Observable<InformationSystem> {
        return this._update$(`${this.config.apiBaseUrl}/api/informationsystem/${data.id}`, data);
    }

    override delete$(data: InformationSystem): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/informationsystem/${data.id}`);
    }

    constructor(@Inject(ApiToken) private config: ApiConfig) {
        super();
    }
}
