import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {excludeDrawerFromNavigationEvent, filterNavigationEndGuard, mapToRouteLastChild, mapToRouteTitle} from '@utils';

const APP_TITLE = 'Managician';

@Injectable({providedIn: 'root'})
export class TitleService {

    private currentPageTitleToTranslate?: string = undefined;
    private currentPageTitle?: string = undefined;

    /** @ignore */
    constructor(
        private router: Router,
        private titleService: Title,
        private translate: TranslateService,
        // eslint-disable-next-line no-empty-function
    ) {
    }

    /**
     * The initialize() method is used to initialise the service and listen to navigation events (NavigationEnd) via the router.
     * It uses filter and transformation operators to extract the current page title from the routing data (snapshot.data.title)
     * and uses it to set the current page title using the "setPageTitle" method.
     */
    initialize(): void {
        this.router.events.pipe(
            filterNavigationEndGuard(),
            excludeDrawerFromNavigationEvent(),
            mapToRouteLastChild(this.router),
            mapToRouteTitle(),
        ).subscribe(title => {
            if (title) {
                this.setPageTitle(title);
            }
        });

        this.translate.onLangChange.subscribe(event => {
            if (this.currentPageTitleToTranslate) {
                this.setPageTitle(this.currentPageTitleToTranslate, this.currentPageTitle);
            }
        });
    }

    /**
     * The setPageTitle() method takes as input a title to be translated (titleToTranslate) and an optional title (title) and uses the translation service (translate) to translate the title,
     * and then uses the title service (titleService) to set the page title using the translation and the optional title, if provided.
     * @param titleToTranslate title to translate
     * @param title optionnal title
     */
    setPageTitle(titleToTranslate: string, title?: string): void {
        this.currentPageTitleToTranslate = titleToTranslate;
        if (!title) {
            this.currentPageTitle = undefined;
            this.translate.get(titleToTranslate).subscribe(
                translation => this.titleService.setTitle(`${translation} | ${APP_TITLE}`)
            );
        } else {
            this.currentPageTitle = title;
            this.translate.get(titleToTranslate).subscribe(
                translation => this.titleService.setTitle(`${title} | ${translation}`)
            );
        }
    }
    /**
     * The setPageTitleWorkspace() method is similar to the previous one, but takes two titles to be translated.
     * @param titleToTranslate title to translate
     * @param labelToTranslate label to translate
     * @param title optionnal title
     */
    setPageTitleWorkspace(titleToTranslate: string, labelToTranslate: string, title: string): void {
        this.translate.get([titleToTranslate, labelToTranslate]).subscribe(
            translation => this.titleService.setTitle(`${translation[labelToTranslate]} ${title} | ${translation[titleToTranslate]}`)
        );
    }
}
