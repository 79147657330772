import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {DataModelStore} from '@data-model/data';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {firstOrNull} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {GlossaryTermProvider} from './glossary-term.provider';
import {GlossaryTermState} from '../models/glossary-term-state.interface';
import {GlossaryTerm} from '../models/glossary-term.model';

export function initializeDataLoader(service: DataLoaderService, provider: GlossaryTermProvider, store: GlossaryTermStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class GlossaryTermStore extends EntityStore<GlossaryTerm, GlossaryTermState> implements OnDestroy {
    #created = new Subject<EntitiesChange<GlossaryTerm>>();
    get created(): Observable<EntitiesChange<GlossaryTerm>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<GlossaryTerm>>();
    get updated(): Observable<EntitiesChange<GlossaryTerm>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<GlossaryTerm>>();
    get deleted(): Observable<EntitiesChange<GlossaryTerm>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectTermsForCurrentIS$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.selectAll$,
        (informationSystem, terms) => {
            if (informationSystem) {
                return terms.filter(term => term.informationSystem?.id === informationSystem.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextGlossaryTermCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    public override onSuccess(change: EntitiesChange<GlossaryTerm>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<GlossaryTerm, GlossaryTermState> {
        return {
            storeName: 'GlossaryTermStore',
            initialState: {ids: [], entities: {}},
            selectId: glossaryTerm => glossaryTerm.id,
        };
    }

    constructor(
        provider: GlossaryTermProvider,
        private ISStore: InformationSystemStore,
        private dataModelStore: DataModelStore,
    ) {
        super(provider);

        this.subs.sink = this.dataModelStore.created.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                // If a term has been generated from the model, we need to refresh the glossary terms store.
                this.reset(); // Ensure we don't have HTTP cache
                this.findAll();
            }
        });

        this.subs.sink = this.dataModelStore.updated.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity && entity.glossaryTerm) {
                this.findOne(entity.glossaryTerm.id);
            }
        });

        this.subs.sink = this.dataModelStore.deleted.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity && entity.glossaryTerm) {
                this.findOne(entity.glossaryTerm.id);
            }
        });

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subs.unsubscribe();
    }
}
