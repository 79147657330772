import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'versionable-menu-item',
    template: `
    <ng-template #loading>
        <ngx-skeleton-loader appearance="line" [theme]="{width: '98%', height: '45px'}"></ngx-skeleton-loader>
    </ng-template>
    <mat-list *ngIf="items; else loading">
        <div
            (click)="onSelect()"
            class="application-requirement-item">
            <div class="title">
                <span class="">{{ title }}</span>
            </div>
            <div class="icon-list">
                <span>{{ items.length }}</span>
                <mat-icon>navigate_next</mat-icon>
            </div>
        </div>
    </mat-list>

    <mat-chip-listbox></mat-chip-listbox>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionableMenuItemComponent<T> {
    @Input() items: T[] | null = null;
    @Input() title = '';

    @Output() navigate = new EventEmitter<void>();

    onSelect(): void {
        this.navigate.emit();
    }
}
