import {computed, effect} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormGroup} from '@angular/forms';
import {ZodIssue, ZodType} from 'zod';

/**
 * Provides validation for a Zod schema in an Angular form.
 * Automatically updates the form errors and valid status when the form value changes.
 *
 * @param form The form containing the model to validate
 * @param schema The Zod schema
 */
export const provideZodInNgFormValidation = (form: FormGroup, schema: ZodType) => {
    const formChanged = toSignal(form.valueChanges);
    const formValidation = computed(() => schema.safeParse(formChanged()));
    const errors = computed(() => {
        const result = formValidation();

        // Maps the errors to a similar dictionary structure as Angular's form errors
        return result.success ? {} : result.error.issues.reduce((map, error) => {
            if (!map[error.path.join('.')]) {
                map[error.path.join('.')] = [];
            }
            map[error.path.join('.')].push(error);

            return map;
        }, {} as Record<string, ZodIssue[]>);
    });

    // Update the angular form errors with Zod errors when the validation result changes
    effect(() => {
        const errorsValue = errors();
        for (let path in errorsValue) {
            const errorArray = errorsValue[path];
            for (let error of errorArray) {
                form.get(path)?.setErrors({[error.code]: error});
            }
        }
    });

    return {
        // Wether the form is valid according to the Zod schema
        valid: computed(() => formValidation().success),
        // The Zod validation result
        errors,
    };
};
