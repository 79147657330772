import {IdType, extract} from '@axiocode/entity';
import {RequiredByKeys} from '@utils';
import {Requirement} from '@versionable/data';

export type FunctionalRequirement = RequiredByKeys<Requirement, 'application'> & {
    formattedDescription?: string;
    type: string;
    emailSubject?: string | null;
    emailBody: string;
    emailRecipient?: string | null;
    emailReplyTo?: string | null;
    nbOpenDiscussions: number;
};

export const functionalRequirementTrackBy = (index: number, item: FunctionalRequirement): IdType => item.id;

export const formatAPI = extract<FunctionalRequirement>({
    id: true,
    name: true,
    description: true,
    code: true,
    formattedDescription: false,
    type: true,
    emailSubject: true,
    emailBody: true,
    emailRecipient: true,
    emailReplyTo: true,
    application: extract({
        id: true,
    }),
    informationSystem: extract({
        id: true,
    }),
    typeClass: false,
    codeAndPrefix: false,
    features: false,
    entityType: false,
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false
});
