import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {BranchInterceptor} from './interceptors/branch.interceptor';
import {BranchProvider} from './services/branch.provider';
import {BranchStore, initializeDataLoader} from './services/branch.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BranchInterceptor,
            multi: true,
        },
    ],

})
export class BranchDataModule {
    constructor(
        loader: DataLoaderService,
        provider: BranchProvider,
        store: BranchStore,
        @Optional() @SkipSelf() parentModule?: BranchDataModule
    ) {
        if (parentModule) {
            throw new Error('BranchDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
