import {HttpContext} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ConvertSnakeToCamelCase, EntityProvider, IdType} from '@axiocode/entity';
import {withCache} from '@ngneat/cashew';
import {Organization} from '@organization/data';
import {ApiConfig, ApiToken} from '@token';
import {Observable} from 'rxjs';

import {User} from '../models/user.model';

@Injectable({providedIn: 'root'})
export class UserProvider extends EntityProvider<User> {
    /**
     * Gets the current user information.
     */
    public fetchCurrentUserInformation$(): Observable<User> {
        return this.http.get<User>(`${this.config.apiBaseUrl}/api/account/me`, {
            context: withCache({
                cache: this.enableCache,
                bucket: this.cacheBucket,
                mode: 'stateManagement',
                ttl: this.ttl,

            }).set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion),
        });
    }

    override findAll$(): Observable<User[]> {
        throw new Error('Method not implemented.');
    }

    override findOne$(id: IdType): Observable<User> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/account/${id}`);
    }

    override create$(data: Partial<User>): Observable<User> {
        return this._create$(`${this.config.apiBaseUrl}/api/organization/${data.organization?.id}/account`, data);
    }

    override update$(data: Partial<User>, method: 'patch' | 'put' = 'patch'): Observable<User> {
        return this._update$(`${this.config.apiBaseUrl}/api/account/${data.id}`, data, method);
    }

    override delete$(data: User): Observable<void> {
        throw new Error('Method not implemented.');
    }

    findByOrganization$(organization: Organization): Observable<User[]> {
        return this.http.get<User[]>(`${this.config.apiBaseUrl}/api/organization/${organization.id}/accounts`, {
            context: (new HttpContext()).set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion),
        });
    }

    findAvailableByOrganization$(organization: Organization): Observable<User[]> {
        return this.http.get<User[]>(`${this.config.apiBaseUrl}/api/organization/${organization.id}/available_accounts`, {
            context: withCache({
                cache: this.enableCache,
                bucket: this.cacheBucket,
                mode: 'cache',
                ttl: this.ttl,

            }).set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion),
        });
    }

    constructor(@Inject(ApiToken) private config: ApiConfig) {
        super();
    }
}
