import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {AppRoutes} from '@routing';
import {NotificationService} from '@ui/notification';
import {Observable, filter, tap} from 'rxjs';

import {Identifiers} from '../../models/identifiers.model';
import {AuthStore} from '../../services/auth.store';
import {AuthModuleConfig, AuthModuleToken} from '../../token';


@Component({
    selector: 'auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    requesting$: Observable<boolean> = this.authStore.selectRequesting$;
    error$: Observable<string | undefined> = this.authStore.selectError$.pipe(
        filter(error => undefined !== error),
        tap(() => this.notificationService.notifyError('LOGIN.UNABLE_TO_LOG_IN'))
    );

    constructor(
        @Inject(AuthModuleToken) public readonly config: AuthModuleConfig,
        private readonly authStore: AuthStore,
        private readonly notificationService: NotificationService,
        public routes: AppRoutes,
        // eslint-disable-next-line no-empty-function
    ) {}

    public authenticate(identifiers: Identifiers): void {
        if (this.config.alwaysRememberMe) {
            identifiers = {
                ...identifiers,
                rememberMe: true,
            };
        }
        this.authStore.login(identifiers);
    }
}
