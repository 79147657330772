import {
    AbstractControl,
    FormControl,
    FormGroup,
    UntypedFormArray, UntypedFormGroup, ValidationErrors,
    Validators
} from '@angular/forms';
import {Application} from '@application/data';
import {DataModel} from '@data-model/data';
import {DataModelAttribute} from '@data-model/data';
import {DataModelRelationship} from '@data-model/data';
import {GlossaryTerm} from '@glossary-term/data';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {BaseFormType, ControlsOf} from '@utils';

export class DataModelType extends BaseFormType<DataModel> {

    private disabledRelations: DataModelRelationship[] = [];

    /**
     * @Inheritdoc
     */
    protected build(): FormGroup {
        const glossaryTermGenerateFormControl = new FormControl<boolean>(false, {nonNullable: true});
        glossaryTermGenerateFormControl.valueChanges.subscribe(value => {
            if (value) {
                this.clearGlossaryTerm();
            }
        });

        const formGroup = new FormGroup({
            name: new FormControl<string | undefined>(undefined, {
                nonNullable: true,
                validators: [Validators.required, Validators.maxLength(this.options.nameMaxLength)]
            }),
            code: new FormControl<number | undefined>(undefined, {nonNullable: true, validators: [Validators.required]}),
            inherits: new FormControl(null),
            description: new FormControl<string>('', {nonNullable: true}),
            type: new FormControl<string | undefined>('class', {nonNullable: true, validators: [Validators.required]}),
            functionalDomains: new FormControl<string[]>([], {nonNullable: true}),
            functionalDomainsControl: new FormControl<string>(''),
            dataModelAttributes: new UntypedFormArray([]),
            dataModelRelationships: new UntypedFormArray([]),
            glossaryTerm: new FormControl<GlossaryTerm | null>(null),
            generatePage: new FormGroup({
                generate: new FormControl<boolean>(false, {nonNullable: true}),
                application: new FormControl<Application | null>(null)
            }),
            generateGlossaryTerm: new FormGroup({
                generate: glossaryTermGenerateFormControl
            }),
        }, [this.compareLabel, this.validateGlossaryTerm, this.validateGeneratePage]);

        formGroup.get('dataModelRelationships')?.valueChanges.subscribe({
            next: dataModelRelationships => {
                if (0 < dataModelRelationships.length) {
                    formGroup.get('type')?.disable();
                } else {
                    formGroup.get('type')?.enable();
                }
            }
        });

        return formGroup;
    }

    compareLabel(formGroup: AbstractControl): ValidationErrors | null {
        formGroup = formGroup as UntypedFormGroup;
        const attributes = formGroup.value.dataModelAttributes;
        const relationships = formGroup.value.dataModelRelationships;

        for (let i = 0; i < relationships.length; i++) {
            for (let j = 0; j < attributes.length; j++) {
                if (relationships[i].label === attributes[j].name) {
                    if (attributes[j].name) {
                        return {attributeNameAndRelationLabelCannotBeTheSame: true};
                    }
                }
            }
        }

        return null;
    }

    validateGlossaryTerm(formGroup: AbstractControl): ValidationErrors | null {
        formGroup = formGroup as UntypedFormGroup;
        const type = formGroup.value.type;
        const generate = formGroup.value.generateGlossaryTerm.generate;
        const term = formGroup.value.glossaryTerm;

        /**
         * If the type is "class" and the user has not checked the "generate" checkbox and the user has not selected a term,
         * we return an error.
         */
        if ('class' === type && !generate && !term) {
            return {glossaryTerm: true};
        }

        return null;
    }

    validateGeneratePage(formGroup: AbstractControl): ValidationErrors | null {
        formGroup = formGroup as UntypedFormGroup;
        const type = formGroup.value.type;
        const generate = formGroup.get('generatePage')?.value.generate;
        const application = formGroup.get('generatePage')?.value.application;

        if ('enum' === type) {
            return null;
        }

        /**
         * If the user has checked the "generate" checkbox and the user has not selected an application, we return an error.
         */
        if (generate && !application) {
            return {application: true};
        }

        return null;
    }


    /**
     * Reset the field "glossaryTerm" to null.
     */
    clearGlossaryTerm(): void {
        this.form?.get('glossaryTerm')?.setValue(null);
        this.form?.updateValueAndValidity();
    }

    /**
     * @Inheritdoc
     *
     */
    override bind(dataModel: DataModel): void {
        // bind general information
        super.bind(dataModel);

        this.disabledRelations = [];

        if (dataModel.type !== 'class') {
            this.clearGlossaryTerm();
        }

        // bind attributes
        let attributes = this.form?.get('dataModelAttributes') as UntypedFormArray;
        attributes.clear();
        if (dataModel.dataModelAttributes) {
            dataModel.dataModelAttributes?.forEach(attribute => {
                const attributeType = new DataModelAttributeType();
                attributes.push(attributeType.getForm(attribute));
            });
        }


        // bind relations
        let relations = this.form?.get('dataModelRelationships') as UntypedFormArray;
        relations.clear();
        if (dataModel.dataModelRelationships) {
            dataModel.dataModelRelationships.forEach(relation => {
                const relationType = new DataModelRelationshipType();
                let relationForm = relationType.getForm(relation);

                // We disabled the whole form if the current model is not the owner of that relation. Read only.
                if (relation.ownerDataModelId !== dataModel.id) {
                    this.disabledRelations.push(relation);
                    for (const field in relationForm.controls) {
                        if ('id' !== field) {
                            relationForm.get(field)?.disable();
                        }
                    }
                }

                relations.push(relationForm);
            });
        }
    }
}

export class DataModelAttributeType extends BaseFormType<DataModelAttribute> {

    /**
     * @Inheritdoc
     */
    protected build(): FormGroup {
        let typeFormControl = new FormControl(undefined, {nonNullable: true});
        typeFormControl.valueChanges.subscribe(value => {
            if ('class' === value) {
                this.addExtras();
            } else {
                this.clearExtras();
            }
        });

        let nameControl = new FormControl(undefined, {
            nonNullable: true, validators: [
                Validators.required, RxwebValidators.unique(), Validators.maxLength(this.options.nameMaxLength)
            ]
        });
        nameControl.markAsTouched();

        return new FormGroup<ControlsOf<DataModelAttribute>>({
            name: nameControl,
            description: new FormControl<string>('', {nonNullable: true}),
            type: typeFormControl,
            orderPosition: new FormControl<number | undefined>(undefined, {nonNullable: true}),
            id: new FormControl(),
            isLinkedToPageItem: new FormControl<boolean>(false, {nonNullable: true}),
            dataModelClass: new FormControl(null)
        });
    }

    /**
     * Empties the fields "dataModelClass" from their values.
     */
    clearExtras(): void {
        this.form?.get('dataModelClass')?.setValue(null);
        this.form?.get('dataModelClass')?.clearValidators();
        this.form?.get('dataModelClass')?.updateValueAndValidity();

    }

    /**
     * Add the fields "dataModelClass".
     */
    addExtras(): void {
        this.form?.get('dataModelClass')?.setValidators(Validators.required);
        this.form?.get('dataModelClass')?.updateValueAndValidity();
    }
}

export class DataModelRelationshipType extends BaseFormType<DataModelRelationship> {

    /**
     * @Inheritdoc
     */
    protected build(): FormGroup {
        let relatedDataModelIdControl = new FormControl(undefined, {
            nonNullable: true, validators: [Validators.required]
        });
        relatedDataModelIdControl.markAsTouched();

        return new FormGroup<ControlsOf<DataModelRelationship>>({
            id: new FormControl(),
            label: new FormControl(undefined, {nonNullable: true, validators: [Validators.maxLength(this.options.nameMaxLength)]}),
            type: new FormControl(undefined, {nonNullable: true}),
            ownerMultiplicity: new FormControl(undefined, {nonNullable: true}),
            ownerDataModelId: new FormControl({value: undefined, disabled: true}, {nonNullable: true}),
            ownerDataModelName: new FormControl(undefined, {nonNullable: true}),
            ownerDataModelAttributeName: new FormControl(undefined, {nonNullable: true}),
            relatedMultiplicity: new FormControl(undefined, {nonNullable: true}),
            relatedDataModelId: relatedDataModelIdControl,
            relatedDataModelAttributeName: new FormControl(undefined, {nonNullable: true}),
            relatedDataModelName: new FormControl(undefined, {nonNullable: true}),
        });
    }
}

