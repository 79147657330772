import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {EMPTY, Observable, Subject, catchError, exhaustMap, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {ActorProvider} from './actor.provider';
import {ActorState} from '../models/actor-state.interface';
import {Actor} from '../models/actor.model';

export function initializeDataLoader(service: DataLoaderService, provider: ActorProvider, store: ActorStore) {
    return () => service.registerLoader(() => {
        store.reset();
        provider.resetCache();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class ActorStore extends EntityStore<Actor, ActorState> {
    #created = new Subject<EntitiesChange<Actor>>();
    get created(): Observable<EntitiesChange<Actor>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Actor>>();
    get updated(): Observable<EntitiesChange<Actor>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Actor>>();
    get deleted(): Observable<EntitiesChange<Actor>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectActorsForCurrentIS$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.selectAll$,
        (IS, actors) => IS ? actors.filter(actor => actor.informationSystem.id === IS.id) : []
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextActorCodeAvailable ?? 1
    );
    readonly selectPlantUmlContent$ = this.select(state => state.plantUmlContent);

    // Effects
    readonly loadPlantUmlContent = this.effect(trigger$ => trigger$.pipe(
        exhaustMap(() => this.provider.findActorsUML$().pipe(
            tap(svg => this.setPlantUmlContent(svg)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    // Reducers
    readonly setPlantUmlContent = this.updater((state, plantUmlContent: string) => ({
        ...state,
        plantUmlContent
    }));

    /** @ignore */
    private subs = new SubSink();

    public override onSuccess(change: EntitiesChange<Actor>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Actor, ActorState> {
        return {
            storeName: 'ActorStore',
            initialState: {ids: [], entities: {}, plantUmlContent: undefined},
            selectId: actor => actor.id,
            sort: sortByCode,
        };
    }

    constructor(protected override provider: ActorProvider, private ISStore: InformationSystemStore) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }
}
