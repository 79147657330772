import {ComponentType} from '@angular/cdk/portal';
import {Injectable, TemplateRef, inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {DialogComponent} from '../components/dialog/dialog.component';

export class DialogConfiguration {
    title?: string;
    contentTemplate?: TemplateRef<any>;
    actionsTemplate?: TemplateRef<any>;
    data?: any = {};
}

export type DialogFactory = (service: DialogService) => DialogHandle;

/**
 * DialogHandle is a pointer on a dialog window. Handles are owned by the DialogService, so they can become invalid
 * if you store them in another component.
 */
export class DialogHandle {
    constructor(
        public id: string,
        public componentInstance: DialogComponent,
        private service: DialogService // eslint-disable-next-line no-empty-function
    ) {}

    public close(result?: any): boolean {
        return this.service.close(this.id, result);
    }

    public valid(): boolean {
        return this.service.dialogValid(this.id);
    }
}

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    /** @ignore */
    private dialogStack: string[] = [];
    /** @ignore */
    private registeredDialogs: Map<string, DialogFactory> = new Map<string, DialogFactory>();
    /** @ignore */
    private dialog: MatDialog = inject(MatDialog);

    /**
     * Creates a new DialogComponent and displays it in a dialog window.
     */
    public open(type: string): DialogHandle | undefined;
    // eslint-disable-next-line no-dupe-class-members
    public open<T extends DialogComponent>(component: string | ComponentType<T>, configuration?: DialogConfiguration): DialogHandle | undefined;
    // eslint-disable-next-line no-dupe-class-members
    public open<T extends DialogComponent>(
        component: string | ComponentType<T>,
        configuration?: DialogConfiguration
    ): DialogHandle | undefined {
        if ('string' === typeof (component)) {
            return this.openRegistered(component);
        }

        if ('object' === typeof (component) || 'function' === typeof (component)) {
            return this.openComponent(component, configuration || {});
        }

        return undefined;
    }


    public openComponent<T extends DialogComponent>(
        component: ComponentType<T>,
        configuration: DialogConfiguration
    ): DialogHandle {
        configuration = Object.assign(new DialogConfiguration(), configuration);
        const dialog = this.dialog.open(component, {
            //width: configuration.width,
            data: {
                title: configuration.title,
                contentTemplate: configuration.contentTemplate,
                actionsTemplate: configuration.actionsTemplate,
                data: configuration.data,
            },
            disableClose: true,
        });

        const handle = new DialogHandle(dialog.id, dialog.componentInstance, this);
        dialog.componentInstance.dialogDismissed.subscribe(() => dialog.close());
        dialog.afterClosed().subscribe(result => this.close(handle.id, result));

        this.dialogStack.push(dialog.id);

        return handle;
    }

    private openRegistered(type: string): DialogHandle | undefined {
        const callback = this.registeredDialogs.get(type);

        return callback ? callback(this) : undefined;
    }

    /**
     * Closes the given dialog if dialogId is provided, otherwise closes the last opened dialog.
     *
     * @return true if a dialog has been closed
     */
    public close(dialogId?: string, result?: any): boolean {
        if (0 === this.dialogStack.length) {
            return false;
        }

        // Get the dialogID (the top dialog if no ID provided) and remove it from the stack
        if (dialogId) {
            this.dialogStack = this.dialogStack.filter(value => dialogId !== value);
        } else {
            dialogId = this.dialogStack.pop();
        }

        // Get the dialog object from the ID and close it
        const dialog = this.dialog.getDialogById(dialogId as string);
        if (dialog) {
            dialog.close(result);

            return true;
        }

        return false;
    }

    /**
     * Closes all dialogs.
     */
    public closeAll(): void {
        for (const id of this.dialogStack) {
            const dialog = this.dialog.getDialogById(id);
            if (dialog) {
                dialog.close();
            }
        }
    }

    /**
     * Registers a creation function for the provided dialog type. Once a dialog is registered that way,
     * you can use open() to open a registered dialog.
     */
    public registerDialog(type: string, creationFct: DialogFactory): void {
        this.registeredDialogs.set(type, creationFct);
    }

    public dialogValid(dialogId: string): boolean {
        return !!this.dialog.getDialogById(dialogId);
    }
}
