import {IdType, extract} from '@axiocode/entity';
import {Versionable} from '@versionable/data';

import {DataModelPartial} from './data-model-partial.model';
import {Term} from './term.model';

type GenerateInformation = {generate: boolean};

export interface GlossaryTerm extends Versionable {
    word: string;
    synonyms: Term[];
    plurals: Term[];
    formattedDescription?: string;
    dataModel?: DataModelPartial;
    nbOpenDiscussions?: number;

    generateDataModel?: GenerateInformation;
}

export const glossaryTermTrackBy = (index: number, item: GlossaryTerm): IdType => item.id;

export const formatAPICreate = extract<GlossaryTerm>({
    id: true,
    code: true,
    word: true,
    description: true,
    formattedDescription: false,
    synonyms: true,
    plurals: true,
    name: false,
    application: extract({
        id: true,
    }),
    informationSystem: extract({
        id: true,
    }),
    typeClass: false,
    codeAndPrefix: false,
    entityType: false,
    dataModel: false,
    generateDataModel: extract({
        generate: true,
    }),
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false
});

export const formatAPIUpdate = extract<GlossaryTerm>({
    id: true,
    code: true,
    word: true,
    description: true,
    formattedDescription: false,
    synonyms: true,
    plurals: true,
    name: false,
    application: extract({
        id: true,
    }),
    informationSystem: extract({
        id: true,
    }),
    typeClass: false,
    codeAndPrefix: false,
    entityType: false,
    dataModel: false,
    generateDataModel: false,
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false
});
