import {CommonModule} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuickMenuLoaderService} from '@application/data';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ConfigurationModule, Hotkeys} from '@configuration';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutes} from '@routing';
import {CkeditorModule} from '@ui/ckeditor';
import {FormModule} from '@ui/form';
import {MaterialModule} from '@ui/material';
import {PipesModule} from '@ui/pipes';
import {TableModule} from '@ui/table';
import {SecurityModule} from '@user/security';
import {VersionableUiModule} from '@versionable/ui';

import {AddButtonComponent} from './components/actions/add.component';
import {DeleteButtonComponent} from './components/actions/delete.component';
import {GlossaryTermDialogComponent} from './components/glossary-term-dialog/glossary-term-dialog.component';
import {GlossaryTermFormComponent} from './components/glossary-term-form/glossary-term-form.component';
import {GlossaryTermTableComponent} from './components/glossary-term-table/glossary-term-table.component';

@NgModule({
    imports: [
        CKEditorModule,
        CkeditorModule,
        CommonModule,
        ConfigurationModule,
        FormModule,
        FormsModule,
        MaterialModule,
        PipesModule,
        ReactiveFormsModule,
        SecurityModule,
        TableModule,
        TranslateModule.forChild(),
        VersionableUiModule,
    ],
    declarations: [
        AddButtonComponent,
        DeleteButtonComponent,
        GlossaryTermDialogComponent,
        GlossaryTermFormComponent,
        GlossaryTermTableComponent,
    ],
    exports: [
        AddButtonComponent,
        DeleteButtonComponent,
        GlossaryTermDialogComponent,
        GlossaryTermFormComponent,
        GlossaryTermTableComponent,
    ]
})
export class GlossaryTermUiModule {
    constructor(
        private quickMenuService: QuickMenuLoaderService,
        private routes: AppRoutes,
        @Optional() @SkipSelf() parentModule?: GlossaryTermUiModule,
    ) {
        if (!parentModule) {
            this.quickMenuService.addItem({
                dialogComponent: GlossaryTermDialogComponent,
                label: 'GLOSSARY.GLOSSARY_TERM',
                dialogTitle: 'GLOSSARY.ADD_GLOSSARY_TERM',
                dialogHotkeys: Hotkeys.ADD_GLOSSARY_TERM,
                isSequence: true,
                detailsUrl: (id, isId) => this.routes.informationSystem.in(isId).glossaryTerm.details(id),
                type: 'is',
            });
        }
    }
}
