import {ComponentType} from '@angular/cdk/portal';
import {Component, OnDestroy} from '@angular/core';
import {Application, ApplicationStore, QuickMenuItem, QuickMenuLoaderService} from '@application/data';
import {IdType} from '@axiocode/entity';
import {BranchStore} from '@branch/data';
import {Hotkeys, UserPreferencesService, UserPreferencesTypes} from '@configuration';
import {DiscussionStore} from '@discussion/data';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {OrganizationStore} from '@organization/data';
import {AppRoutes} from '@routing';
import {DialogComponent, DialogHandle, DialogService} from '@ui/dialog';
import {DrawerService} from '@ui/drawer';
import {HotkeysDialogComponent} from '@ui/hotkeys';
import {AuthStore} from '@user/auth';
import {UserStore} from '@user/data';
import {exhaustiveMatchGuard} from '@utils';
import {Versionable} from '@versionable/data';
import {combineLatest, map, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {ToolbarLocalStore} from '../../+state/toolbar-local.store';

@Component({
    selector: 'toolbar-application',
    templateUrl: './application-toolbar.component.html',
    styleUrls: ['./application-toolbar.component.scss'],
    providers: [ToolbarLocalStore]
})
export class ApplicationToolbarComponent implements OnDestroy {
    quickMenuItems$ = this.quickMenuService.items$.pipe(
        map(items => items.sort((a, b) => a.label < b.label ? -1 : 1))
    );
    ISQuickMenuItems$ = this.quickMenuItems$.pipe(
        map(items => items.filter(item => 'is' === item.type))
    );
    applicationQuickMenuItems$ = this.quickMenuItems$.pipe(
        map(items => items.filter(item => 'application' === item.type))
    );
    vm$ = combineLatest([
        this.ISStore.selectSelectedEntity$,
        this.branchStore.selectCurrentBranch$,
        this.applicationStore.selectSelectedEntity$,
        this.organizationStore.selectSelectedEntity$,
        this.store.selectIsLoggedIn$,
        this.userStore.selectCurrentUser$,
        this.ISQuickMenuItems$,
        this.applicationQuickMenuItems$,
        this.store.selectIsSearchOpened$,
        this.discussionStore.selectAllOpenedDiscussions$,
        this.preferencesService.changes$,
    ], (currentIS, currentBranch, currentApplication, currentOrganization, isLoggedIn, currentUser, ISMenuItems, applicationMenuItems, isSearchOpened, allOpenedDiscussions, preferences) => (
        {
            currentIS, currentBranch, currentApplication, currentOrganization, isLoggedIn, currentUser, ISMenuItems, applicationMenuItems, isSearchOpened,
            allOpenedDiscussions: allOpenedDiscussions.length,
            discoveryMode: false !== preferences[UserPreferencesTypes.SETTINGS_DISCOVERY_MODE],
        }
    ));

    hotkeys = Hotkeys;

    #dialog?: DialogHandle = undefined;
    #subs = new SubSink();

    constructor(
        private authStore: AuthStore,
        private userStore: UserStore,
        private ISStore: InformationSystemStore,
        private branchStore: BranchStore,
        private applicationStore: ApplicationStore,
        private organizationStore: OrganizationStore,
        private store: ToolbarLocalStore,
        private discussionStore: DiscussionStore,
        private quickMenuService: QuickMenuLoaderService,
        private dialogService: DialogService,
        private drawerService: DrawerService,
        private preferencesService: UserPreferencesService,
        public routes: AppRoutes,
    ) {
        this.organizationStore.findAll();
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    openInputSearch(): void {
        this.store.setIsSearchOpened(true);
        setTimeout(() => {
            document.getElementById('searchInput')?.focus();
        }, 0);
    }

    openDialogAdd(item: QuickMenuItem, application: Application | undefined, informationSystem: InformationSystem | undefined): void {
        // Check if the data is available to open the dialog
        if (
            undefined === application && undefined === informationSystem
            || 'application' === item.type && undefined === application
            || 'is' === item.type && undefined === informationSystem
        ) {
            return;
        }

        this.closeDialog();
        this.#dialog = this.dialogService.open(item.dialogComponent as ComponentType<DialogComponent>, {
            title: item.dialogTitle,
            data: {
                application: application,
                informationSystem: informationSystem,
                displaySaveAndRedirect: true,
            }
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAccepted.subscribe(() => {
            this.closeDialog();
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAcceptedRedirect.subscribe(value => {
            this.closeDialog();
            item.detailsUrl((value as Versionable).id, informationSystem!.id, application?.id).navigate();
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAcceptedAndAdd.subscribe(() => {
            // Re-open the dialog only when the application has been updated with the newest codes
            this.applicationStore.selectSelectedEntity$.pipe(
                take(1),
                tap(app => this.openDialogAdd(item, app ?? application, informationSystem))
            ).subscribe();
        });
    }

    openHotkeys(): void {
        this.#dialog = this.dialogService.open(HotkeysDialogComponent);
    }

    closeDialog(): void {
        this.#dialog?.close();
    }

    goTo(destination: MenuLinks): void {
        switch (destination.destination) {
            case 'application_selection':
                this.drawerService.closeDrawer().then(() => this.routes.application.selection().navigate());
                break;

            case 'application_details':
                this.drawerService.closeDrawer().then(() => this.routes.informationSystem.in(destination.informationSystem.id).application.details(destination.application.id).navigate());
                break;

            case 'organizations':
                this.drawerService.closeDrawer().then(() => this.routes.organization.list().navigate());
                break;

            case 'user_details':
                this.drawerService.closeDrawer().then(() => this.routes.user.details(destination.userId).navigate());
                break;

            case 'discussion':
                if (destination.application) {
                    this.drawerService.closeDrawer().then(() => this.routes.informationSystem.in(destination.informationSystem.id).application.in(destination.application?.id).discussion.list().navigate());
                } else {
                    this.drawerService.closeDrawer().then(() => this.routes.informationSystem.in(destination.informationSystem.id).discussion.list().navigate());
                }
                break;

            default: exhaustiveMatchGuard(destination);
        }
    }

    toggleDiscoveryMode(): void {
        const flag = this.preferencesService.get(UserPreferencesTypes.SETTINGS_DISCOVERY_MODE, false) as boolean;
        this.preferencesService.save(UserPreferencesTypes.SETTINGS_DISCOVERY_MODE, !flag);
    }

    logout(): void {
        this.authStore.logout();
    }

}

type MenuLinks =
    | {
        destination: 'application_selection',
    }
    | {
        destination: 'application_details',
        informationSystem: InformationSystem,
        application: Application,
    }
    | {
        destination: 'organizations',
    }
    | {
        destination: 'user_details',
        userId: IdType,
    }
    | {
        destination: 'discussion',
        application?: Application,
        informationSystem: InformationSystem,
    };
