import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {TableProvider} from './table.provider';
import {TableState} from '../models/table-state.interface';
import {Table} from '../models/table.model';

export function initializeDataLoader(service: DataLoaderService, provider: TableProvider, store: TableStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class TableStore extends EntityStore<Table, TableState> {
    #created = new Subject<EntitiesChange<Table>>();
    get created(): Observable<EntitiesChange<Table>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Table>>();
    get updated(): Observable<EntitiesChange<Table>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Table>>();
    get deleted(): Observable<EntitiesChange<Table>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectTablesForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, tables) => {
            if (application) {
                return tables.filter(table => table.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.applicationStore.selectSelectedEntity$,
        (IS, application) => IS?.applications.find(app => app.id === application?.id)?.nextDataTableCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    // Selectors
    readonly selectAllTablesByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        tables => tables.filter(table => table.features.find(f => f.id === feature.id))
    );

    public override onSuccess(change: EntitiesChange<Table>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Table, TableState> {
        return {
            storeName: 'TableStore',
            initialState: {ids: [], entities: {}},
            selectId: table => table.id,
            sort: sortByCode,
        };
    }

    constructor(provider: TableProvider, private applicationStore: ApplicationStore, private ISStore: InformationSystemStore) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }
}
