<form [formGroup]="form">
    <div class="row sup-height">
        <div class="col small">
            <mat-form-field>
                <mat-label>{{ 'COMMON.CODE' | translate }}</mat-label>
                <input type="number" matInput required formControlName="code" data-cy="datamodel-code-input">
                <span matTextPrefix>{{ prefix }}&nbsp;</span>
                <mat-error *ngIf="form.get('code')?.hasError('required')">
                    {{ 'ERROR.INPUT_NOT_COMPLETE' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('code')?.hasError('api')">
                    {{ form.get('code')?.getError('api') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'GLOSSARY.TERM' | translate }}</mat-label>
                <input
                    [maxlength]="formType.getOptions().nameMaxLength"
                    formControlName="word"
                    matInput required data-cy="glossary-term-input">
                <mat-error
                    *ngIf="form.get('word')?.hasError('required')"
                    data-cy="glossary-term-word-error">
                    {{ 'ERROR.INPUT_NOT_COMPLETE' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="form.get('word')?.hasError('maxlength')"
                    data-cy="glossary-term-word-error">
                    {{ 'ERROR.INPUT_MAX_LENGTH' | translate: {'max':
                    form.get('word')?.getError('maxlength')?.requiredLength} }}
                </mat-error>
                <mat-error *ngIf="form.get('word')?.hasError('api')" data-cy="glossary-term-word-error">
                    {{ form.get('word')?.getError('api') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'GLOSSARY.SYNONYMS' | translate }}</mat-label>
                <mat-chip-grid #synonymsChipList>
                    <mat-chip-row
                        *ngFor="let term of synonyms"
                        (removed)="removeSynonym(term)">
                        {{ term.word }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input matInput
                        [matChipInputFor]="synonymsChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addSynonym($event)"
                        formControlName="synonymControl"
                        data-cy="glossary-term-synonym-input">
                    <mat-error
                        *ngIf="form.get('synonymControl')?.hasError('maxlength')"
                        data-cy="glossary-term-synonym-error">
                        {{ 'ERROR.INPUT_MAX_LENGTH' | translate: {'max':
                        form.get('synonymControl')?.getError('maxlength')?.requiredLength} }}
                    </mat-error>
                </mat-chip-grid>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'GLOSSARY.PLURALS' | translate }}</mat-label>
                <mat-chip-grid #pluralsChipList>
                    <mat-chip-row
                        *ngFor="let term of plurals"
                        (removed)="removePlural(term)">
                        {{ term.word }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input matInput
                        [matChipInputFor]="pluralsChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addPlural($event)"
                        formControlName="pluralControl"
                        data-cy="glossary-term-plurals-input">
                    <mat-error
                        *ngIf="form.get('pluralControl')?.hasError('maxlength')"
                        data-cy="glossary-term-plural-error">
                        {{ 'ERROR.INPUT_MAX_LENGTH' | translate: {'max':
                        form.get('pluralControl')?.getError('maxlength')?.requiredLength} }}
                    </mat-error>
                </mat-chip-grid>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="isCreateMode">
        <div class="col" formGroupName="generateDataModel">
            <mat-checkbox formControlName="generate">
                {{ 'GLOSSARY.GENERATE_DATA_MODEL' | translate }}
            </mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-label class="ckeditor-label">{{ 'COMMON.DESCRIPTION' | translate }}</mat-label>
            <ckeditor-mentions
                [currentRequirement]="glossaryTerm"
                formControlName="description"
                data-cy="glossary-term-description-input">
            </ckeditor-mentions>
            <mat-error
                *ngIf="form.get('description')?.hasError('api')"
                data-cy="glossary-term-description-error">
                {{ form.get('description')?.getError('api') }}
            </mat-error>
        </div>
    </div>
</form>
