import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {AxiocodeManagedTableModule} from '@axiocode/managed-table';
import {TranslateModule} from '@ngx-translate/core';

import {PaginatorIntlService} from './services/paginator-intl.service';
import {TranslateManagedTableComponent} from './translate-managed-table/translate-managed-table.component';

@NgModule({
    imports: [
        AxiocodeManagedTableModule,
        CommonModule,
        RouterModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        TranslateManagedTableComponent,
    ],
    exports: [
        TranslateManagedTableComponent,
    ],
    providers: [
        {provide: MatPaginatorIntl, useClass: PaginatorIntlService}
    ],
})
export class TableModule {}
