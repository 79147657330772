import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {
    CkeditorMentionsComponent
} from './components/ckeditor-mentions/ckeditor-mentions.component';

@NgModule({
    imports: [CommonModule, CKEditorModule, FormsModule],
    declarations: [CkeditorMentionsComponent],
    exports: [CkeditorMentionsComponent],
})
export class CkeditorModule {}
