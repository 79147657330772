import {InjectionToken} from '@angular/core';

/**
 * Configuration object for libraries that need access to the API.
 */
export interface ApiConfig {
    /** The API base URL, without the trailing '/' */
    apiBaseUrl: string;
    ttl: number;
}

/** @ignore */
export const ApiToken = new InjectionToken<ApiConfig>(
    'ApiConfig'
);


/**
 * Configuration object for libraries that need global configuration.
 */
export interface ProjectConfig {
    release: string;
}

/** @ignore */
export const ProjectConfigToken = new InjectionToken<ProjectConfig>(
    'ProjectConfig'
);
