import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GlossaryTerm} from '@glossary-term/data';
import {BaseFormType} from '@utils';

export class GlossaryTermType extends BaseFormType<GlossaryTerm> {

    /**
     * @inheritDoc
     */
    protected build(): FormGroup {
        return new FormGroup({
            code: new FormControl<number|undefined>(undefined, {nonNullable: true, validators: [Validators.required]}),
            word: new FormControl<string|undefined>(undefined, {nonNullable: true, validators: [
                Validators.required, Validators.maxLength(this.options.nameMaxLength)
            ]}),
            description: new FormControl<string>('', {nonNullable: true}),
            synonyms: new FormControl<string[]>([], {nonNullable: true}),
            plurals: new FormControl<string[]>([], {nonNullable: true}),
            synonymControl: new FormControl<string>('', {nonNullable: true, validators: [Validators.maxLength(this.options.nameMaxLength)]}),
            pluralControl: new FormControl<string>('', {nonNullable: true, validators: [Validators.maxLength(this.options.nameMaxLength)]}),
            generateDataModel: new FormGroup({
                generate: new FormControl<boolean>(false, {nonNullable: true})
            })
        });
    }

}
