import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {HotkeysModule} from '@ui/hotkeys';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

import {ActionButtonComponent} from './components/action-button/action-button.component';
import {AddButtonComponent} from './components/action-button/add-button.component';
import {CancelButtonComponent} from './components/action-button/cancel-button.component';
import {DeleteButtonComponent} from './components/action-button/delete-button.component';
import {EditButtonComponent} from './components/action-button/edit-button.component';
import {ExportButtonComponent} from './components/action-button/export-button.component';
import {ListButtonComponent} from './components/action-button/list-button.component';
import {MenuButtonComponent} from './components/action-button/menu-button.component';
import {SaveButtonComponent} from './components/action-button/save-button.component';
import {ValidationButtonComponent} from './components/action-button/validation-button.component';
import {ViewButtonComponent} from './components/action-button/view-button.component';
import {SaveActionsButtonComponent} from './components/save-actions-button/save-actions-button.component';

const MODULES = [
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
];

const COMPONENTS = [
    ActionButtonComponent,
    AddButtonComponent,
    CancelButtonComponent,
    DeleteButtonComponent,
    EditButtonComponent,
    ExportButtonComponent,
    ListButtonComponent,
    MenuButtonComponent,
    SaveActionsButtonComponent,
    SaveButtonComponent,
    ValidationButtonComponent,
    ViewButtonComponent,
];

@NgModule({
    imports: [
        CommonModule,
        HotkeysModule,
        RouterModule,
        TranslateModule.forChild(),
        ...MODULES,
    ],
    declarations: [
        ...COMPONENTS,
    ],
    exports: [
        ...MODULES,
        ...COMPONENTS,
    ],
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
    ]
})
export class MaterialModule {}
