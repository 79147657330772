export function modelsEqualityById<T extends {id: string | number}>(modelA: T, modelB: T): boolean {
    if (!modelA || !modelB || !modelA.id || !modelB.id) {
        return false;
    }

    return modelA.id === modelB.id;
}

export function modelsEqualityByDefault(modelA: any, modelB: any): boolean {
    if (!modelA || !modelB) {
        return false;
    }

    return modelA === modelB;
}

export function filterRequirementWithCode<T extends {id: number | string, name?: string, code?: number}>(search: string, allEntities: T[]): T[] {
    if (!search.length) {
        return allEntities;
    }
    search = search.toLowerCase();

    // filter the entities
    return allEntities.filter(entity => {
        if ((entity.name?.toLowerCase().indexOf(search) as number) > -1) {
            return true;
        }

        return (entity.code?.toString().indexOf(search) as number) > -1;
    });
}

export function filterOnName<T extends {id: number | string, name?: string}>(search: string, data: T[]): T[] {
    if (!search.length) {
        return data;
    }
    search = search.toLowerCase();

    // filter the data
    return data.filter(entity => {
        if (entity.name) {
            return entity.name?.toLowerCase().indexOf(search) > -1;
        }

        return false;
    });
}

export function isDeepEqual(object1: object, object2: object): boolean {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) {
        return false;
    }

    for (var key of objKeys1) {
        const value1 = (object1 as any)[key];
        const value2 = (object2 as any)[key];

        const isObjects = isObject(value1) && isObject(value2);

        if ((isObjects && !isDeepEqual(value1, value2)) ||
            (!isObjects && value1 !== value2)
        ) {
            return false;
        }
    }

    return true;
}

export function isObject(object: any): object is object {
    return object !== null && 'object' === typeof object;
}
