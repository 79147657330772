import {Component, inject} from '@angular/core';
import {ScrollingService} from '@ui/scrolling';
import {combineLatest, distinctUntilChanged, map, startWith} from 'rxjs';

import {PlanItem} from '../models/item.interface';
import {NavigationPlanService} from '../services/navigation-plan.service';

@Component({
    selector: 'plan-list',
    template: `
    <div class="sidenav-navigation">
        <mat-action-list class="nav-plan" *ngIf="entries$ | async as entries">
            <mat-list-item
                *ngFor="let entry of entries; let index = index; let first = first"
                (click)="scrollTo(entry)"
                [ngClass]="{'first-element': first, 'active': entry.visible}"
                [class]="'depth-' + entry.level">
                <span class="material-symbols-outlined">chevron_right</span>
                <span class="mat-mdc-list-text">{{ entry.label }}</span>
            </mat-list-item>
        </mat-action-list>
    </div>
    `
})
export class NavigationPlanComponent {

    #planService = inject(NavigationPlanService);
    #scrollingService = inject(ScrollingService);
    entries$ = combineLatest([this.#planService.entries, this.#scrollingService.scrolling.pipe(startWith(0))]).pipe(
        map(([entries, _]) => entries.map(entry => ({...entry, visible: this.#scrollingService.isElementVisible(entry.element)}))),
        distinctUntilChanged(),
    );

    scrollTo(entry: PlanItem): void {
        this.#scrollingService.scrollToElement(entry.element.nativeElement, {behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
}
