import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {ApiToken} from '@token';
import {Observable, iif, switchMap, take} from 'rxjs';

import {BranchStore} from '../services/branch.store';

@Injectable()
export class BranchInterceptor implements HttpInterceptor {
    private store = inject(BranchStore);
    private readonly config = inject(ApiToken);

    /** @ignore */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add "branch=<id of current branch>" to all requests
        // NOTE [2024-07-22]: Add a regex to exclude PATCH /api/application/{id} requests
        if (request.url.startsWith(this.config.apiBaseUrl) && !/\/api\/application\/[0-9]+\/?$/mg.test(request.url)) {
            return this.store.selectCurrentBranch$.pipe(
                take(1),
                switchMap(branch =>
                    iif(() => undefined !== branch,
                        next.handle(request.clone({params: request.params.set('branch', branch?.id as string)})),
                        next.handle(request)
                    )
                )
            );
        }

        return next.handle(request);
    }
}
