import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {HotkeysModule} from '@ui/hotkeys';
import {PipesModule} from '@ui/pipes';
import {SecurityModule} from '@user/security';

import {DownloadDiagramComponent} from './components/download-diagram/download-diagram.component';
import {SvgDiagramComponent} from './components/svg-diagram/svg-diagram.component';

@NgModule({
    imports: [CommonModule, SecurityModule, HotkeysModule, MatIconModule, MatButtonModule, PipesModule],
    declarations: [SvgDiagramComponent, DownloadDiagramComponent],
    exports: [SvgDiagramComponent, DownloadDiagramComponent],
})
export class SvgDiagramModule {}
