import {Injectable, inject} from '@angular/core';
import {DataLoaderService} from '@information-system/data';
import {ComponentStore} from '@ngrx/component-store';
import {EMPTY, catchError, exhaustMap, tap} from 'rxjs';

import {ConfigurationProvider} from './configuration-provider.service';
import {Configuration} from '../models/configuration.interface';

export interface ConfigurationState {
    configurations?: Configuration;
}

const defaultState: ConfigurationState = {
    configurations: undefined,
};

export function initializeDataLoader(service: DataLoaderService, provider: ConfigurationProvider, store: ConfigurationStore) {
    return () => service.registerLoader(() => provider.findAll$().pipe(tap(data => store.setConfigurations(data))));
}

@Injectable({providedIn: 'root'})
export class ConfigurationStore extends ComponentStore<ConfigurationState> {
    #provider = inject(ConfigurationProvider);

    // Selectors
    readonly selectConfigurations$ = this.select(state => state.configurations);

    // Effects
    readonly load = this.effect(trigger$ => trigger$.pipe(
        exhaustMap(() => this.#provider.findAll$().pipe(
            tap(configurations => this.setConfigurations(configurations)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    // Reducers
    readonly setConfigurations = this.updater((state, configurations: Configuration) => ({
        ...state,
        configurations
    }));

    constructor() {
        super(defaultState);
    }
}
