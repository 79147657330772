import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {HotkeysModule as NgneatHotkeysModule} from '@ngneat/hotkeys';
import {TranslateModule} from '@ngx-translate/core';

import {HotkeysDialogComponent} from './components/hotkeys-dialog/hotkeys-dialog.component';
import {HotkeyDirective} from './directives/hotkey.directive';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        NgneatHotkeysModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        HotkeysDialogComponent,
        HotkeyDirective,
    ],
    exports: [
        NgneatHotkeysModule,
        HotkeysDialogComponent,
        HotkeyDirective,
    ],
})
export class HotkeysModule {}
