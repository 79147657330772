import {Actor} from '@actor/data';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseFormType, ControlsOf} from '@utils';

export class ActorType extends BaseFormType<Actor> {

    /**
     * @inheritDoc
     */
    protected build(): FormGroup {
        return new FormGroup<ControlsOf<Partial<Actor>>>({
            code: new FormControl<number | undefined>(undefined, {nonNullable: true, validators: [Validators.required]}),
            name: new FormControl(undefined, {
                nonNullable: true, validators: [
                    Validators.required, Validators.maxLength(this.options.nameMaxLength)
                ]
            }),
            inherits: new FormControl(undefined),
            description: new FormControl<string>('', {nonNullable: true}),
            isRole: new FormControl(false, {nonNullable: true})
        });
    }

}
