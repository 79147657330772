import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {BreadcrumbModule} from '@ui/breadcrumb';
import {MaterialModule} from '@ui/material';
import {NavigationPlanModule} from '@ui/navigation-plan';
import {VersionableUiModule} from '@versionable/ui';

import {BranchCreateButtonComponent} from './components/actions/branch-create-button.component';
import {ApplicationSwitcherComponent} from './components/application-switcher/application-switcher.component';
import {BranchSwitcherComponent} from './components/branch-switcher/branch-switcher.component';
import {InformationSystemSwitcherComponent} from './components/information-system-switcher/information-system-switcher.component';
import {SidebarComponent} from './components/sidebar.component';

@NgModule({
    imports: [CommonModule,
        MaterialModule,
        BreadcrumbModule,
        NavigationPlanModule,
        VersionableUiModule,
        TranslateModule.forChild(),
        BranchSwitcherComponent,
        ApplicationSwitcherComponent,
        InformationSystemSwitcherComponent,
        BranchCreateButtonComponent
    ],
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
})
export class SidebarModule {

}
