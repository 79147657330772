<mat-form-field [formGroup]="formGroupParam">
    <mat-label>
        <ng-content select="[label]"></ng-content>
    </mat-label>
    <mat-select [formControlName]="controlName" [compareWith]="compare">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="searchControl"
                [noEntriesFoundLabel]="'COMMON.NO_RESULTS' | translate"
                [placeholderLabel]="'COMMON.SEARCH' | translate"
                autofocus>
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="isNullable"
            [value]="null">
        </mat-option>
        <mat-option
            *ngFor="let data of datasFiltered$ | async"
            [value]="getDataValue(data, getDataId)">
            <ng-container *ngIf="content">
                <ng-container *ngTemplateOutlet="content; context: {data:data}"></ng-container>
            </ng-container>
        </mat-option>
    </mat-select>
    <mat-error data-cy="usecase-feature-error">
        <ng-content select="[error]"></ng-content>
    </mat-error>
</mat-form-field>
