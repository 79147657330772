import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialModule} from '@ui/material';

import {NavigationPlanComponent} from './components/navigation-plan.component';
import {PlanItemDirective} from './directives/plan-item.directive';

@NgModule({
    imports: [CommonModule, MaterialModule],
    declarations: [
        NavigationPlanComponent,
        PlanItemDirective,
    ],
    exports: [
        NavigationPlanComponent,
        PlanItemDirective
    ],
})
export class NavigationPlanModule {}
