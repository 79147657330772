import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityState, EntityStore, IdType} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {Observable, Subject, Subscription, distinctUntilChanged, tap} from 'rxjs';

import {DependencyProvider} from './dependency.provider';
import {DependencyLink} from '../models/requirement.model';

export interface DependencyState extends EntityState<DependencyLink> {}

export function initializeDataLoader(service: DataLoaderService, provider: DependencyProvider, store: DependencyStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class DependencyStore extends EntityStore<DependencyLink, DependencyState> implements OnDestroy {
    #created = new Subject<EntitiesChange<DependencyLink>>();
    get created(): Observable<EntitiesChange<DependencyLink>> {
        return this.#created.asObservable();
    }

    #deleted = new Subject<EntitiesChange<DependencyLink>>();
    get deleted(): Observable<EntitiesChange<DependencyLink>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subscription: Subscription;

    // Selectors
    readonly selectSuppliersForRequirement$ = (id: IdType) => this.select(
        this.selectAll$,
        dependencies => dependencies.filter(d => undefined !== d && d.dependentRequirement.id === id)
    );

    readonly selectDependenciesForRequirement$ = (id: IdType) => this.select(
        this.selectAll$,
        dependencies => dependencies.filter(d => undefined !== d && d.supplierRequirement.id === id)
    );

    protected override getEntityAdapter(): EntityAdapter<DependencyLink, DependencyState> {
        return {
            storeName: 'DependencyStore',
            initialState: {ids: [], entities: {}},
            selectId: dep => dep.id,
        };
    }

    constructor(provider: DependencyProvider, private ISStore: InformationSystemStore) {
        super(provider);

        this.subscription = this.ISStore.selectSelectedEntity$.pipe(
            // Filter out if the IS is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current IS
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }

    public override onSuccess(change: EntitiesChange<DependencyLink>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            default: break;
        }
    }
}
