import {IdType, extract} from '@axiocode/entity';
import {Requirement, Versionable} from '@versionable/data';

import {DataModelAttribute} from './data-model-attribute.model';
import {DataModelRelationship} from './data-model-relationship.model';

type GlossaryTerm = Versionable & {word: string};
type GenerateInformation = {generate: boolean};

export interface DataModel extends Requirement {
    functionalDomains: string[];
    formattedDescription?: string | undefined;
    inherits?: DataModel | null;
    dataModelAttributes?: DataModelAttribute[];
    nbAttributes?: number | undefined;
    nbRelations?: number | undefined;
    dataModelRelationships?: DataModelRelationship[];
    type?: string | undefined;
    glossaryTerm?: GlossaryTerm | null;

    generatePage?: GenerateInformation & {application: {id: IdType}};
    generateGlossaryTerm?: GenerateInformation;
    nbOpenDiscussions?: number;
}

export const dataModelTrackBy = (index: number, item: DataModel): IdType => item.id;

export const formatAPICreate = extract<DataModel>({
    id: true,
    name: true,
    description: true,
    code: true,
    formattedDescription: false,
    application: extract({
        id: true,
    }),
    informationSystem: extract({
        id: true,
    }),
    inherits: true,
    functionalDomains: true,
    dataModelAttributes: true,
    nbAttributes: false,
    nbRelations: false,
    dataModelRelationships: true,
    type: true,
    typeClass: false,
    codeAndPrefix: false,
    glossaryTerm: extract({
        id: true,
    }),
    generatePage: extract({
        generate: true,
        application: extract({
            id: true,
        }),
    }),
    generateGlossaryTerm: extract({
        generate: true,
    }),
    features: false,
    entityType: false,
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false
});

export const formatAPIUpdate = extract<DataModel>({
    id: true,
    name: true,
    description: true,
    code: true,
    formattedDescription: false,
    application: extract({
        id: true,
    }),
    informationSystem: extract({
        id: true,
    }),
    inherits: true,
    functionalDomains: true,
    dataModelAttributes: true,
    nbAttributes: false,
    nbRelations: false,
    dataModelRelationships: true,
    type: true,
    typeClass: false,
    codeAndPrefix: false,
    glossaryTerm: extract({
        id: true,
    }),
    generatePage: false,
    generateGlossaryTerm: false,
    features: false,
    entityType: false,
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false
});
