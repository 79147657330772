import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {UserProviderToken} from '@user/auth';

import {UserProvider} from './services/user.provider';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
    providers: [
        {provide: UserProviderToken, useClass: UserProvider}
    ]
})
export class UserDataModule {
    constructor(@Optional() @SkipSelf() parentModule?: UserDataModule) {
        if (parentModule) {
            throw new Error('UserDataModule is already loaded. Import it in the AppModule only.');
        }
    }
}
