import {Inject, Injectable} from '@angular/core';
import {EntityProvider, IdType} from '@axiocode/entity';
import {ApiConfig, ApiToken} from '@token';
import {Observable} from 'rxjs';

import {AccountPermission} from '../models/account-permission.model';

@Injectable({providedIn: 'root'})
export class AccountPermissionProvider extends EntityProvider<AccountPermission> {
    override findAll$(): Observable<AccountPermission[]> {
        throw new Error('Method not implemented.');
    }

    override findOne$(id: IdType): Observable<AccountPermission> {
        throw new Error('Method not implemented.');
    }

    override create$(data: Partial<AccountPermission>): Observable<AccountPermission> {
        throw new Error('Method not implemented.');
    }

    override update$(data: Partial<AccountPermission>, method: 'patch' | 'put'): Observable<AccountPermission> {
        return this._update$(`${this.config.apiBaseUrl}/api/account-permission/${data.id}`, data, method);
    }

    override delete$(data: AccountPermission): Observable<void> {
        throw new Error('Method not implemented.');
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
    ) {
        super();

        this.ttl = this.config.ttl;
    }
}
