<h2 mat-dialog-title *ngIf="configuration.title">{{ configuration.title | translate }}</h2>

<mat-dialog-content class="modal" data-cy="mat-dialog-term">
    <glossary-term-form
        #glossaryTermForm
        [informationSystem]="configuration.data.informationSystem"
        [glossaryTerm]="configuration.data.editGlossaryTerm"
        (submitted)="submitted($event)">
    </glossary-term-form>
</mat-dialog-content>

<mat-dialog-actions [attr.align]="'end'">
    <button
        *ngIf="(saveState$ | async) === false"
        mat-button
        data-cy="cancel-dialog-button"
        (click)="dismissDialog()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>

    <ui-save-actions-button
        *ngIf="(saveState$ | async) === false"
        [disabled]="(glossaryTermForm ? (glossaryTermForm.isInvalid | async) : true) ?? false"
        [dropdownDisplay]="!glossaryTermForm?.glossaryTerm?.id"
        (submitted)="this.handleValidationForm($event.redirect, $event.another)">
    </ui-save-actions-button>

    <div class="saving-state" *ngIf="saveState$ | async">
        <span>{{ 'COMMON.SAVING' | translate }}</span>
        <mat-progress-spinner mode="indeterminate" [diameter]="20"></mat-progress-spinner>
    </div>
</mat-dialog-actions>
