import {Actor, ActorLocalStore} from '@actor/data';
import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, inject} from '@angular/core';
import {DialogComponent} from '@ui/dialog';

import {ActorFormComponent} from '../actor-form/actor-form.component';

@Component({
    selector: 'actor-dialog',
    templateUrl: './actor-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActorLocalStore]
})
export class ActorDialogComponent extends DialogComponent implements AfterViewInit {

    @ViewChild(ActorFormComponent) actorForm?: ActorFormComponent;

    #localStore = inject(ActorLocalStore);

    redirect = false;
    another = false;
    saveState$ = this.#localStore.selectSaveState$;

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngAfterViewInit(): void {
        super.addActionShortcuts(undefined === this.configuration.data.editActor);
        this.#localStore.setCurrentActor(this.configuration.data?.editActor);
    }

    submitted(next: Actor): void {
        if (!this.another) {
            this.redirect ? this.dialogAcceptedRedirect.next(next) : this.dialogAccepted.next(next);
        } else {
            this.dialogAcceptedAndAdd.next(next);
        }
        super.removeActionShortcuts();
    }

    dismissDialog(): void {
        this.dialogDismissed.next();
    }

    public override handleValidationForm(redirect: boolean, another: boolean): void {
        this.redirect = redirect;
        this.another = another;
        this.actorForm?.validate();
    }
}
