import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BranchDialogComponent} from '@branch/ui';
import {TranslateModule} from '@ngx-translate/core';
import {HotkeysModule} from '@ui/hotkeys';
import {MaterialModule} from '@ui/material';
import {PipesModule} from '@ui/pipes';
import {SecurityModule} from '@user/security';

import {
    ApplicationToolbarComponent
} from './components/application-toolbar/application-toolbar.component';
import {LangSwitcherComponent} from './components/application-toolbar/lang-switcher/lang-switcher.component';
import {OrganizationSwitcherComponent} from './components/application-toolbar/organization-switcher/organization-switcher.component';
import {QuickMenuItemComponent} from './components/application-toolbar/quick-menu-item/quick-menu-item.component';
import {GlobalSearchComponent} from './components/global-search/global-search.component';

@NgModule({
    imports: [
        CommonModule,
        HotkeysModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        SecurityModule,
        TranslateModule.forChild(),
        PipesModule,
        FormsModule,
        BranchDialogComponent,
    ],
    declarations: [
        ApplicationToolbarComponent,
        GlobalSearchComponent,
        LangSwitcherComponent,
        OrganizationSwitcherComponent,
        QuickMenuItemComponent,
    ],
    exports: [ApplicationToolbarComponent],
})
export class ToolbarModule {}
