<ng-container *ngIf="vm$ | async as vm">
    <div *ngIf="vm.isLoggedIn && vm.currentUser" class="super-sidebar super-sidebar--alt">
        <div class="top-nav">
            <div class="si-version si-version--lean">
                <div class="top-nav-wrapper">
                    <div class="si" [ngClass]="!vm.currentApplication ? 'active' : ''">
                        <sidebar-information-system-switcher [currentIS]="vm.currentIS" />
                    </div>
                    <div>
                        <button
                            *ngIf="vm.currentIS"
                            (click)="routes.informationSystem.details(vm.currentIS.id).navigate()"
                            [matTooltip]="'INFORMATION_SYSTEM.CURRENT_NAV' | translate"
                            mat-icon-button
                            data-cy="is-current-nav">
                            <span class="material-symbols-outlined">house</span>
                        </button>
                    </div>
                </div>

                <div class="top-nav-wrapper">
                    <div class="version">
                        <sidebar-branch-switcher [currentBranch]="vm.currentBranch" />
                    </div>
                    <div>
                        @if (vm.currentIS) {
                        <sidebar-branch-create-button
                            [sourceBranch]="vm.currentIS.mainBranch" />
                        }
                    </div>
                </div>

                <div class="top-nav-wrapper">
                    <div class="application-selection" [ngClass]="vm.currentApplication ? 'active' : ''">
                        <sidebar-application-switcher [currentApplication]="vm.currentApplication" />
                    </div>
                    <button
                        *ngIf="vm.currentApplication"
                        (click)="this.routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.details(vm.currentApplication.id).navigate()"
                        [matTooltip]="'APPLICATION.CURRENT_NAV' | translate"
                        mat-icon-button
                        data-cy="application-current-nav">
                        <span class="material-symbols-outlined">house</span>
                    </button>
                </div>
            </div>
        </div>

        <ng-container *ngIf="(entries$ | async)?.length; else showNavigation">
            <mgcn-breadcrumb></mgcn-breadcrumb>
        </ng-container>
        <!-- NAVIGATION -->
        <ng-template #showNavigation>
            <div class="application-requirement-list" *ngIf="vm.currentIS">
                <ng-container *ngIf="!vm.currentApplication">
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.informationSystem?.id).actor.list().url)"
                        (navigate)="routes.informationSystem.in(vm.informationSystem?.id).actor.list().navigate()"
                        [items]="vm.actors" [title]="'ACTOR.ACTORS' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.informationSystem?.id).dataModel.list().url)"
                        (navigate)="routes.informationSystem.in(vm.informationSystem?.id).dataModel.list().navigate()"
                        [items]="vm.dataModels" [title]="'DATA_MODEL.DATA_MODELS' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.informationSystem?.id).glossaryTerm.list().url)"
                        (navigate)="routes.informationSystem.in(vm.informationSystem?.id).glossaryTerm.list().navigate()"
                        [items]="vm.terms" [title]="'GLOSSARY.GLOSSARY_TERM' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.informationSystem?.id).features.list().url)"
                        (navigate)="routes.informationSystem.in(vm.informationSystem?.id).features.list().navigate()"
                        [items]="vm.featuresIS" [title]="'FEATURE.FEATURES' | translate">
                    </versionable-menu-item>
                </ng-container>
                <ng-container *ngIf="vm.currentApplication">
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).feature.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).feature.list().navigate()"
                        [items]="vm.featuresApp"
                        [title]="'FEATURE.FEATURES' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).functionalRequirement.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).functionalRequirement.list().navigate()"
                        [items]="vm.funcreqs"
                        [title]="'FUNCTIONAL_REQUIREMENT.FUNCTIONAL_REQUIREMENTS' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).nonFunctionalRequirement.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).nonFunctionalRequirement.list().navigate()"
                        [items]="vm.nonfuncreqs"
                        [title]="'NON_FUNCTIONAL_REQUIREMENT.NON_FUNCTIONAL_REQUIREMENTS' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).page.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).page.list().navigate()"
                        [items]="vm.pages"
                        [title]="'PAGE.PAGES' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).useCase.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).useCase.list().navigate()"
                        [items]="vm.usecases"
                        [title]="'USE_CASE.USE_CASES' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).form.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).form.list().navigate()"
                        [items]="vm.forms"
                        [title]="'FORM.FORMS' | translate">
                    </versionable-menu-item>
                    <versionable-menu-item
                        [class.active]="checkIsActive(routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).table.list().url)"
                        (navigate)="routes.informationSystem.in(vm.currentApplication.informationSystem.id).application.in(vm.currentApplication.id).table.list().navigate()"
                        [items]="vm.tables"
                        [title]="'TABLE.TABLES' | translate">
                    </versionable-menu-item>
                </ng-container>
            </div>
        </ng-template>
        <plan-list></plan-list>
    </div>
</ng-container>
