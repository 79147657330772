import {HttpContext, HttpParams} from '@angular/common/http';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ConvertSnakeToCamelCase, EntityProvider, IdType} from '@axiocode/entity';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {ApiConfig, ApiToken} from '@token';
import {Observable, Subscription, distinctUntilChanged, map, tap} from 'rxjs';

import {DataModel} from '../models/data-model.model';

@Injectable({providedIn: 'root'})
export class DataModelProvider extends EntityProvider<DataModel> implements OnDestroy {
    /** @ignore */
    private IS: InformationSystem | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<DataModel[]> {
        if (undefined === this.IS) {
            throw new Error('Current information system is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystem/${this.IS.id}/datamodels`);
    }

    override findOne$(id: IdType): Observable<DataModel> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/datamodel/${id}`);
    }

    override create$(data: Partial<DataModel>): Observable<DataModel> {
        return this._create$(`${this.config.apiBaseUrl}/api/datamodel`, data);
    }

    override update$(data: Partial<DataModel>, method: 'patch' | 'put'): Observable<DataModel> {
        // Renvoyer les relations uniquement si le modèle de donnée édité est l'owner des relations
        if (data.dataModelRelationships && data.dataModelRelationships.length > 0) {
            data.dataModelRelationships = data.dataModelRelationships.filter(relationship => relationship.ownerDataModelId === data.id);
        }

        return this._update$(`${this.config.apiBaseUrl}/api/datamodel/${data.id}`, data, method);
    }

    override delete$(data: DataModel): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/datamodel/${data.id}`);
    }

    findAllClassDiagramContent$(center?: DataModel, domains?: string[]): Observable<string> {
        if (undefined === this.IS) {
            throw new Error('Current application is not defined.');
        }

        // add the GET parameters to the URL
        let options = new HttpParams();
        if (center) {
            options = options.set('datamodel', center.id);
        }
        if (domains?.length) {
            options = options.set('domains', domains.join(','));
        }

        return this.http.get<{svg: string}>(`${this.config.apiBaseUrl}/api/informationsystem/${this.IS.id}/umldiagram/datamodels`, {
            params: options,
            context: (new HttpContext()).set(ConvertSnakeToCamelCase, true),
            withCredentials: false
        }).pipe(
            map(url => url.svg)
        );
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        ISStore: InformationSystemStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = ISStore.selectSelectedEntity$.pipe(
            // Filter out if the IS is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current IS
            tap(() => this.resetCache()),
            tap(IS => this.IS = IS),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
