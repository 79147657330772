import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {GenerateRequirementModel} from '@data-model/data';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {EMPTY, Observable, Subject, catchError, exhaustMap, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {FeatureProvider, FeatureSvg} from './feature.provider';
import {FeatureState} from '../models/feature-state.interface';
import {Feature} from '../models/feature.model';

export function initializeDataLoader(service: DataLoaderService, provider: FeatureProvider, store: FeatureStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class FeatureStore extends EntityStore<Feature, FeatureState, FeatureProvider> {
    #created = new Subject<EntitiesChange<Feature>>();
    get created(): Observable<EntitiesChange<Feature>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Feature>>();
    get updated(): Observable<EntitiesChange<Feature>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Feature>>();
    get deleted(): Observable<EntitiesChange<Feature>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    #generatedCrud = new Subject<Feature>();
    get generatedCrud(): Observable<Feature> {
        return this.#generatedCrud.asObservable();
    }

    // Selectors
    readonly selectFeaturesForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, features) => {
            if (application) {
                return features.filter(feature => feature.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectFeaturesForCurrentIS$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.selectAll$,
        (IS, features) => {
            if (IS) {
                return features.filter(feature => feature.informationSystem.id === IS.id);
            }

            return [];
        }
    );
    readonly selectPlantUmlContent$ = this.select(state => state.plantUmlContent);
    readonly selectPlantUmlWithoutCrudContent$ = this.select(state => state.plantUmlContentWithoutCrud);
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.applicationStore.selectSelectedEntity$,
        (IS, application) => IS?.applications.find(app => app.id === application?.id)?.nextFeatureCodeAvailable ?? 1
    );

    // Effects
    readonly loadPlantUmlContent = this.effect((trigger$: Observable<Feature>) => trigger$.pipe(
        exhaustMap(feature => this.provider.findFeatureUML$(feature).pipe(
            tap(svg => this.setPlantUmlContents(svg)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));
    readonly generateCrud = this.effect((trigger$: Observable<GenerateRequirementModel>) => trigger$.pipe(
        switchMap(data => this.provider.createFeatureCrudFromDataModel(data).pipe(
            tap(feature => this.#generatedCrud.next(feature)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    // Reducers
    readonly setPlantUmlContents = this.updater((state, featureSvg: FeatureSvg) => ({
        ...state,
        plantUmlContent: featureSvg.featureSvg,
        plantUmlContentWithoutCrud: featureSvg.featureSvgWithoutCrud
    }));

    /** @ignore */
    private subs = new SubSink();

    public override onSuccess(change: EntitiesChange<Feature>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Feature, FeatureState> {
        return {
            storeName: 'FeatureStore',
            initialState: {ids: [], entities: {}, plantUmlContent: undefined, plantUmlContentWithoutCrud: undefined},
            selectId: feature => feature.id,
            sort: sortByCode,
        };
    }

    constructor(provider: FeatureProvider, private applicationStore: ApplicationStore, private ISStore: InformationSystemStore) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }

    resetCache(): void {
        this.provider.resetCache();
    }
}
