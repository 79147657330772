import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class InformationSystemDataModule {
    constructor(
        @Optional() @SkipSelf() parentModule?: InformationSystemDataModule
    ) {
        if (parentModule) {
            throw new Error('InformationSystemDataModule is already loaded. Import it in the AppModule only.');
        }
    }
}
