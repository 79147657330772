import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, input, model, output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {Branch, BranchDTO, branchSchema, BranchStore} from '@branch/data';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {TranslateModule} from '@ngx-translate/core';
import {CkeditorModule} from '@ui/ckeditor';
import {MaterialModule} from '@ui/material';
import {ControlsOf, firstOrNull, provideZodInNgFormValidation} from '@utils';
import {merge} from 'rxjs';

@Component({
    selector: 'branch-form',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MaterialModule, TranslateModule, CKEditorModule, CkeditorModule],
    templateUrl: './branch-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchFormComponent {
    branch = input<Branch>();
    editMode = computed(() => !!this.branch());
    submitted = output<Branch>();
    isInvalid = output<boolean>();
    isSaving = model(false);

    #store = inject(BranchStore);
    #errorHandler = inject(ErrorHandlerService);
    #cd = inject(ChangeDetectorRef);

    form = new FormGroup<ControlsOf<BranchDTO>>({
        name: new FormControl('', {nonNullable: true}),
        reminderOfTheNeed: new FormControl('', {nonNullable: true}),
        issueLink: new FormControl(null),
    });
    formValidation = provideZodInNgFormValidation(this.form, branchSchema);

    constructor() {
        effect(() => {
            this.isInvalid.emit(!this.formValidation.valid());
        });
        effect(() => {
            const data = this.branch();
            if (data) {
                this.form.patchValue(data);
            }
        });

        merge(this.#store.created, this.#store.updated).pipe(takeUntilDestroyed()).subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.emit(entity);
                this.isSaving.set(false);
            }
        });

        this.#store.error.pipe(takeUntilDestroyed()).subscribe(error => {
            this.isSaving.set(false);
            let apiFormError = this.#errorHandler.handleError(error);
            if (apiFormError?.formErrors) {
                this.#errorHandler.applyFormErrors(apiFormError.formErrors, this.form);
                this.#cd.detectChanges();
            }
        });
    }

    validate(): void {
        if (this.form.valid) {
            let data = this.form.value;
            if (!this.editMode()) {
                this.#store.create(data);
            } else {
                data = {...this.branch(), ...data};
                this.#store.update({data, method: 'patch'});
            }
            this.isSaving.set(true);
        }
    }
}
