import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Observable, Subject} from 'rxjs';

import {OrganizationProvider} from './organization.provider';
import {OrganizationState} from '../models/organization-state.interface';
import {Organization} from '../models/organization.model';

@Injectable({providedIn: 'root'})
export class OrganizationStore extends EntityStore<Organization, OrganizationState> {

    #created = new Subject<EntitiesChange<Organization>>();
    get created(): Observable<EntitiesChange<Organization>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Organization>>();
    get updated(): Observable<EntitiesChange<Organization>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Organization>>();
    get deleted(): Observable<EntitiesChange<Organization>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    public override onSuccess(change: EntitiesChange<Organization>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected override getEntityAdapter(): EntityAdapter<Organization, OrganizationState> {
        return {
            storeName: 'OrganizationStore',
            initialState: {ids: [], entities: {}},
            selectId: organization => organization.id,
        };
    }

    constructor(provider: OrganizationProvider) {
        super(provider);
    }
}
