import {HttpErrorResponse} from '@angular/common/http';
import {tapResponse} from '@ngrx/operators';
import {Observable, concatMap, exhaustMap, iif, mergeMap, of, switchMap} from 'rxjs';

import {IdType} from '../../interfaces/entity-state.interface';
import {EntityStoreInterface} from '../../interfaces/entity-store.interface';
import {EntityProvider} from '../entity-provider.service';

/**
 * GET request. Replaces the store state with newly fetched entities.
 */
export function provideFindAll<T>(
    provider: EntityProvider<T>, store: EntityStoreInterface<T>, trigger$: Observable<void>
) {
    return trigger$.pipe(
        exhaustMap(() => provider.findAll$().pipe(
            tapResponse(
                entities => {
                    store.setMany(entities);
                    store.onSuccess({type: 'get', entities});
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        store.onError(error);
                    }
                }
            )
        ))
    );
}

/**
 * GET request. Upserts the fetched entity in the state.
 */
export function provideFindOne<T>(
    provider: EntityProvider<T>, store: EntityStoreInterface<T>, trigger$: Observable<IdType>
) {
    return trigger$.pipe(
        mergeMap(id => provider.findOne$(id).pipe(
            tapResponse(
                entity => {
                    store.upsertOne(entity);
                    store.onSuccess({type: 'get', entities: [entity]});
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        store.onError(error);
                    }
                }
            )
        ))
    );
}

/**
 * POST request. Adds the entity to the state.
 */
export function provideCreate<T>(
    provider: EntityProvider<T>, store: EntityStoreInterface<T>, trigger$: Observable<Partial<T>>
) {
    return trigger$.pipe(
        concatMap(data => provider.create$(data).pipe(
            switchMap(data => iif(
                () => undefined !== store.afterCreate,
                store.afterCreate ? store.afterCreate(data) : of(data),
                of(data)
            )),
            tapResponse(
                entity => {
                    provider.resetCache(); // next findAll or findOne needs to fetch the new entity
                    store.upsertOne(entity);
                    store.onSuccess({type: 'post', entities: [entity]});
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        store.onError(error);
                    }
                }
            )
        ))
    );
}

/**
 * PATCH/PUT request. Updates the entity in the state.
 */
export function provideUpdate<T>(
    provider: EntityProvider<T>,
    store: EntityStoreInterface<T>,
    trigger$: Observable<{data: Partial<T>, method?: 'patch' | 'put'}>
) {
    return trigger$.pipe(
        concatMap(({data, method}) => provider.update$(data, method ?? 'patch').pipe(
            tapResponse(
                entity => {
                    provider.resetCache(); // next findAll or findOne needs to fetch the updated entity
                    store.upsertOne(entity);
                    store.onSuccess({type: 'patch', entities: [entity]});
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        store.onError(error);
                    }
                }
            )
        ))
    );
}

/**
 * DELETE request. Updates the entity in the state.
 */
export function provideDelete<T>(
    provider: EntityProvider<T>,
    store: EntityStoreInterface<T>,
    trigger$: Observable<T>
) {
    return trigger$.pipe(
        mergeMap(data => provider.delete$(data).pipe(
            tapResponse(
                () => {
                    provider.resetCache(); // next findAll or findOne needs to be up to date
                    store.removeOne(data);
                    store.onSuccess({type: 'delete', entities: [data]});
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        store.onError(error);
                    }
                }
            )
        ))
    );
}
