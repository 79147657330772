<ng-container *ngIf="isLoadingApplication$ | async as isLoading">
    <loader-application-loader
        *ngIf="isLoading.loading; else contents"
        [value]="isLoading.progression"
    >
    </loader-application-loader>
    <ng-template #contents>
        <div class="layout-main-content">
            <toolbar-application class="toolbar-application toolbar-application--dark toolbar-application--lean"></toolbar-application>
            <div
                class="main-layout-container"
                autosize
                *ngIf="!isLoading.loading"
            >
                <sidebar-sidebar></sidebar-sidebar>
                <div class="main-container">
                    <router-outlet></router-outlet>
                    <drawer-drawer></drawer-drawer>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>

<!-- <axio-debug-toolbar></axio-debug-toolbar> -->
