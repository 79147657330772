import {Injectable} from '@angular/core';
import {GlossaryTerm} from '@glossary-term/data';
import {Observable, first, map, of} from 'rxjs';

import {MentionStore} from './mention.store';
import {Mention} from '../models/mention.interface';

@Injectable({
    providedIn: 'root'
})
export class MentionConversionService {
    /** @ignore */
    constructor(
        private mentionStore: MentionStore,
        // eslint-disable-next-line no-empty-function
    ) {}

    /**
     * Returns the description string without mentions.
     * @param description string
    * @param completeAfterOneValue boolean If true, the observable will complete() after the first value is sent
     */
    public convertDescriptionMention(description: string | undefined, completeAfterOneValue = true): Observable<string> {
        if (undefined === description) {
            return of('');
        }

        if (false === completeAfterOneValue) {
            return this.mentionStore.selectMentions$.pipe(
                map(elements => this.replaceMentionsInString(elements, description))
            );
        }

        return this.mentionStore.selectMentions$.pipe(
            first(),
            map(elements => this.replaceMentionsInString(elements, description))
        );
    }

    /**
     * Finds all the mentions tags and replaces each one by the entity name.
     * @param description string
     */
    public replaceMentionsInString(elements: Mention[], description: string, removeMention: boolean = false): string {
        let replace = description;
        const allMatches = description?.matchAll(/<span class="mention(?<status>[^<]*)" data-id="(?<id>[^<]+)" data-type="(?<type>[^<]+)" data-entity="(?<entity>[^<]+)" data-entity-id="(?<entityId>[^<]+)">(?<text>[^<]+)<\/span>/g);
        if (!allMatches) {
            return replace;
        }
        for (const match of allMatches) {
            let elementMatch: Mention | undefined = undefined;
            let type = match.groups ? match.groups['type'] : undefined;
            switch (type) {
                case 'system':
                    if ('applicationcomponent' === match.groups?.['entity']) {
                        elementMatch = elements.find(element => {
                            // Number conversion because match.groups?.id is the id of the AppComponent / Application
                            return 'applicationcomponent' === element.entity && element.entityId === Number(match.groups?.['entityId']);
                        });
                    } else {
                        // application
                        elementMatch = elements.find(element => {
                            // Number conversion because match.groups?.id is the id of the AppComponent / Application
                            return 'application' === element.entity && element.entityId === Number(match.groups?.['entityId']);
                        });
                    }
                    break;

                case 'glossaryterm': {
                    elementMatch = elements.find(element => {
                        return 'GlossaryTerm' === element.entity && element.entityId === Number(match.groups?.['entityId']);
                    });

                    let mentionValue = '';
                    if (!elementMatch) {
                        replace = replace.replace(
                            match[0],
                            mentionValue
                        );

                        return replace;
                    }
                    if (elementMatch.data) {
                        const matchedTerm = elementMatch.data as GlossaryTerm;

                        if (matchedTerm.word?.toLowerCase() !== match.groups?.['text'].toLowerCase()) {
                            // matchedTerm.plurals?.forEach((term: Term) => {
                            //     if (term.word?.toLowerCase() === match.groups?.['text'].toLowerCase()) {
                            //         mentionValue = match.groups?.['text'] as string;
                            //     }
                            // });
                            // matchedTerm.synonyms?.forEach((term: Term) => {
                            //     if (term.word?.toLowerCase() === match.groups?.['text'].toLowerCase()) {
                            //         mentionValue = match.groups?.['text'] as string;
                            //     }
                            // });

                            // Temp fix => n'update pas le text du terme si c'est un synonyme ou un pluriel et qu'il a été changé
                            // issue #692 https://gitlab.axiocode.net:50463/axiolab/managician/backlog/-/issues/692
                            mentionValue = match.groups?.['text'] as string;
                        } else {
                            mentionValue = match.groups?.['text'] as string;
                        }
                    }

                    if (removeMention || '' === mentionValue) {
                        replace = replace.replace(
                            match[0],
                            mentionValue
                        );

                        return replace;
                    }

                    return replace;
                }

                default:
                    elementMatch = elements.find(
                        element => type === element.type && element.entityId.toString() === match.groups?.['entityId'].toString()
                    );
                    break;
            }

            let mentionText = elementMatch ? elementMatch?.text : match.groups?.['text'];
            let status = elementMatch ? '' : ' deleted';

            let replaceValue;
            if (removeMention) {
                replaceValue = mentionText ?? '';
            } else {
                replaceValue = '<span class="mention' + status + '" data-id="' + match.groups?.['id'] + '" data-type="' + match.groups?.['type'] + '" data-entity="' + match.groups?.['entity'] + '" data-entity-id="' + match.groups?.['entityId'] + '">' + mentionText + '</span>';
            }

            replace = replace.replace(
                match[0],
                replaceValue
            );
        }

        return replace;
    }
}
