import {CommonModule} from '@angular/common';
import {Component, inject, input} from '@angular/core';
import {Branch} from '@branch/data';
import {BranchCreateActionService} from '@branch/ui';
import {Hotkeys} from '@configuration';
import {TranslateModule} from '@ngx-translate/core';
import {HotkeysModule} from '@ui/hotkeys';
import {MaterialModule} from '@ui/material';

@Component({
    selector: 'sidebar-branch-create-button',
    standalone: true,
    imports: [CommonModule, MaterialModule, TranslateModule, HotkeysModule],
    providers: [BranchCreateActionService],
    template: `
    @if (!editBranch()) {
    <button
        (click)="createAction.openDialogCreateBranch()"
        [hotkey]="hotkeys.IS_ADD_BRANCH"
        [matTooltip]="'BRANCH.ADD_TOOLTIP' | translate: {'branch': sourceBranch().name}"
        hotkeyGroup="KEYMAP.GROUP.GENERAL"
        hotkeyDescription="KEYMAP.IS_ADD_BRANCH"
        mat-icon-button
        data-cy="toolbar-newbranch">
        <span class="material-symbols-outlined">add</span>
    </button>
    } @else {
    <button
        (click)="createAction.openDialogCreateBranch(editBranch())"
        [matTooltip]="'BRANCH.EDIT_TOOLTIP' | translate: {'branch': editBranch()?.name}"
        mat-icon-button
        data-cy="toolbar-editbranch">
        <span class="material-symbols-outlined">edit</span>
    </button>
    }
    `
})
export class BranchCreateButtonComponent {
    sourceBranch = input.required<Pick<Branch, 'id' | 'name'>>();
    editBranch = input<Branch>();

    createAction = inject(BranchCreateActionService);

    hotkeys = Hotkeys;
}
