import {Inject, Injectable, OnDestroy} from '@angular/core';
import {EntityProvider, IdType} from '@axiocode/entity';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {ApiConfig, ApiToken} from '@token';
import {Observable, Subscription, distinctUntilChanged, tap} from 'rxjs';

import {Branch} from '../models/branch.model';
import {Diff} from '../models/diff.model';

@Injectable({providedIn: 'root'})
export class BranchProvider extends EntityProvider<Branch> implements OnDestroy {
    /** @ignore */
    private IS: InformationSystem | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<Branch[]> {
        if (undefined === this.IS) {
            throw new Error('Current information system is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystem/${this.IS.id}/branches`);
    }

    override findOne$(id: IdType): Observable<Branch> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/branch/${id}`);
    }

    override create$(data: Partial<Branch>): Observable<Branch> {
        if (undefined === this.IS) {
            throw new Error('Current information system is not defined.');
        }

        return this._create$(`${this.config.apiBaseUrl}/api/branch/${this.IS.mainBranch.id}/clone`, data);
    }

    override update$(data: Partial<Branch>, method: 'patch' | 'put'): Observable<Branch> {
        return this._update$(`${this.config.apiBaseUrl}/api/branch/${data.id}`, data, method);
    }

    override delete$(data: Branch): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/branch/${data.id}`);
    }

    diff$(branch: Branch, origin?: Branch): Observable<Diff[]> {
        const url = origin ? `${this.config.apiBaseUrl}/api/branch/${branch.id}/diff/${origin.id}` : `${this.config.apiBaseUrl}/api/branch/${branch.id}/diff`;

        return this.http.get<Diff[]>(url);
    }

    merge$(branch: Branch): Observable<void> {
        return this.http.post<void>(`${this.config.apiBaseUrl}/api/branch/${branch.id}/diff/validate`, {});
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        ISStore: InformationSystemStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = ISStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current application
            tap(() => this.resetCache()),
            tap(IS => this.IS = IS),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
