import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {PageProvider} from './services/page.provider';
import {PageStore, initializeDataLoader} from './services/page.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class PageDataModule {
    constructor(
        loader: DataLoaderService, provider: PageProvider, store: PageStore,
        @Optional() @SkipSelf() parentModule?: PageDataModule
    ) {
        if (parentModule) {
            throw new Error('PageDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
