<form [formGroup]="form">
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'BRANCH.NAME' | translate }}</mat-label>
                <input [maxlength]="255" matInput required formControlName="name" data-cy="branch-name-input">
                @for (error of formValidation.errors()['name']; track error.code) {
                <mat-error>{{ 'zod.' + error.code | translate: error }}</mat-error>
                }
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-label class="ckeditor-label">{{ 'BRANCH.REMINDER_OF_NEED' | translate }}</mat-label>
            <ckeditor-mentions formControlName="reminderOfTheNeed"></ckeditor-mentions>
            @for (error of formValidation.errors()['reminderOfTheNeed']; track error.code) {
            <mat-error>{{ 'zod.' + error.code | translate: error }}</mat-error>
            }
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'BRANCH.ISSUE_LINK' | translate }}</mat-label>
                <input matInput formControlName="issueLink">
                @for (error of formValidation.errors()['issueLink']; track error.code) {
                <mat-error>{{ 'zod.' + error.code | translate: error }}</mat-error>
                }
            </mat-form-field>
        </div>
    </div>
</form>
