import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {tapResponse} from '@ngrx/operators';
import {Observable, switchMap, tap} from 'rxjs';

import {PasswordResetService} from './password-reset.service';

export interface PasswordForgottenState {
    requesting: boolean;
    requestSuccess: boolean;
    error?: string;
}

const initialState: PasswordForgottenState = {
    requesting: false,
    requestSuccess: false,
};

@Injectable()
export class PasswordForgottenStore extends ComponentStore<PasswordForgottenState> {
    // Selectors
    readonly selectError$: Observable<string | undefined> = this.select(state => state.error);
    readonly selectRequesting$: Observable<boolean> = this.select(state => state.requesting);
    readonly selectRequestSuccess$: Observable<boolean> = this.select(state => state.requestSuccess);

    // Effects
    readonly sendPasswordForgottenRequest = this.effect((email$: Observable<string>) => {
        return email$.pipe(
            tap(() => this.onRequest()),
            switchMap(email =>
                this.passwordResetService.passwordForgotten(email).pipe(
                    tapResponse(
                        () => this.onResponse(),
                        (error: HttpErrorResponse) => this.onError('authstore.sendPasswordForgottenRequest.error')
                    )
                )
            )
        );
    });

    // Reducers
    readonly onError = this.updater((state, error: string) => ({
        ...state,
        error,
        requesting: false,
        requestSuccess: false,
    }));
    readonly onRequest = this.updater(state => ({
        ...state,
        error: undefined,
        requesting: true,
        requestSuccess: false,
    }));
    readonly onResponse = this.updater(state => ({
        ...state,
        error: undefined,
        requesting: false,
        requestSuccess: true,
    }));

    constructor(private readonly passwordResetService: PasswordResetService) {
        super(initialState);
    }
}
