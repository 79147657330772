import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {TableProvider} from './services/table.provider';
import {TableStore, initializeDataLoader} from './services/table.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class TableDataModule {
    constructor(
        loader: DataLoaderService, provider: TableProvider, store: TableStore,
        @Optional() @SkipSelf() parentModule?: TableDataModule
    ) {
        if (parentModule) {
            throw new Error('TableDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeDataLoader(loader, provider, store)();
    }
}
