import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {QuickMenuItem} from '@application/data';

@Component({
    selector: 'toolbar-quick-menu-item',
    template: `
    <a mat-menu-item
        [hotkey]="item.dialogHotkeys"
        [hotkeyDescription]="item.label"
        hotkeyGroup="KEYMAP.GROUP.DIALOG"
        [hotkeySequence]="item.isSequence">
        <mat-icon>add</mat-icon>
        <div>
            <div>{{ item.label | translate }}</div>
            <div *ngIf="displayShortcut">
                <kbd
                    [innerHTML]="item.dialogHotkeys | hotkeysShortcut: ' + ':('COMMON.HOTKEYS_THEN' | translate)">
                </kbd>
            </div>
        </div>
    </a>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickMenuItemComponent {
    @Input() item!: QuickMenuItem; // @todo angular 16 mmake required
    @Input() displayShortcut = true;
}
