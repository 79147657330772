import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {ManagedTableComponent} from './components/managed-table.component';
import {ManagedTableMaterialComponent} from './components/material/managed-table-material.component';
import {ArrayAccessorService} from './services/array-accessor.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
    ],
    declarations: [
        ManagedTableComponent,
        ManagedTableMaterialComponent,
    ],
    exports: [
        ManagedTableComponent,
        ManagedTableMaterialComponent,
    ],
    providers: [
        ArrayAccessorService,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AxiocodeManagedTableModule {}
