import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Observable, Subject} from 'rxjs';

import {AccountPermissionProvider} from './account-permission.provider';
import {AccountPermissionState} from '../models/account-permission-state.interface';
import {AccountPermission} from '../models/account-permission.model';

@Injectable({providedIn: 'root'})
export class AccountPermissionStore extends EntityStore<AccountPermission, AccountPermissionState> {
    #created = new Subject<EntitiesChange<AccountPermission>>();
    get created(): Observable<EntitiesChange<AccountPermission>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<AccountPermission>>();
    get updated(): Observable<EntitiesChange<AccountPermission>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<AccountPermission>>();
    get deleted(): Observable<EntitiesChange<AccountPermission>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    public override onSuccess(change: EntitiesChange<AccountPermission>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default:
                break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<AccountPermission, AccountPermissionState> {
        return {
            storeName: 'AccountPermissionStore',
            initialState: {ids: [], entities: {}},
            selectId: accountPermission => accountPermission.id,
        };
    }

    constructor(provider: AccountPermissionProvider) {
        super(provider);
    }
}
