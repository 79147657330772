import {Application} from '@application/data';
import {IdType} from '@axiocode/entity';
import {InformationSystem} from '@information-system/data';
import {exhaustiveMatchGuard} from '@utils';

export interface Versionable {
    id: IdType;
    name: string;
    code: number;
    description?: string;
    updatedBy: VersionableUser;
    updatedAt: string;
    nbOpenDiscussions?: number

    informationSystem: InformationSystem;
    application?: Application;

    codeAndPrefix?: string; // search only ? @todo refacto into another interface
    typeClass?: VersionableType; // search only ? @todo refacto into another interface
    entityType: VersionableApiType;
}

export type SimpleVersionable = Pick<Versionable, 'id'>;

export interface VersionableUser {
    id: string;
    firstname?: string;
    lastname?: string;
}

export type VersionableType = 'actor' | 'datamodel' | 'feature' | 'nonfunctionalrequirement' | 'page' | 'functionalrequirement' | 'glossaryterm' | 'usecase' | 'form' | 'table' | 'appcomponent';
export type VersionableApiType = 'Actor' | 'DataModel' | 'Feature' | 'NonFunctionalRequirement' | 'NonFunctionalRequirementPage' | 'FunctionalRequirement' | 'GlossaryTerm' | 'UseCase' | 'Form' | 'DataTable' | 'AppComponent';
export type VersionableState = 'view' | 'edition';

export const convertType = (type: VersionableApiType): VersionableType => {
    switch (type) {
        case 'Actor': return 'actor';
        case 'DataModel': return 'datamodel';
        case 'DataTable': return 'table';
        case 'Feature': return 'feature';
        case 'Form': return 'form';
        case 'FunctionalRequirement': return 'functionalrequirement';
        case 'GlossaryTerm': return 'glossaryterm';
        case 'NonFunctionalRequirement': return 'nonfunctionalrequirement';
        case 'NonFunctionalRequirementPage': return 'page';
        case 'UseCase': return 'usecase';
        case 'AppComponent': return 'appcomponent';
        default: return exhaustiveMatchGuard(type);
    }
};

export function addTypeToVersionables(versionables: Versionable[], typeClass: VersionableType): Versionable[] {
    let newVersionables: Versionable[] = [];
    versionables.forEach(versionable => newVersionables.push(addTypeToVersionable(versionable, typeClass)));

    return newVersionables;
}

export function addTypeToVersionable(versionable: Versionable, typeClass: VersionableType): Versionable {
    return {...versionable, typeClass: typeClass};
}
