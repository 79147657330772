import {EntityAdapter} from '../../interfaces/entity-adapter.interface';
import {Dictionary, EntityState, IdType} from '../../interfaces/entity-state.interface';

/**
 * Replaces the state with the entities.
 */
export function provideSetMany<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T[]
) {
    let entities: Dictionary<T> = {};
    let ids: IdType[] = [];

    for (let entity of data) {
        const id = adapter.selectId(entity);
        ids.push(id);
        entities[id] = entity;
    }

    state = {
        ...state,
        ids,
        entities
    };

    return adapter.sort ? sort(state, adapter) : state;
}

/**
 * Adds entities to the state if it does not contain it already.
 */
export function provideAddMany<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T[]
) {
    const ids = [...state.ids];
    const entities = {...state.entities};

    for (let entity of data) {
        const id = adapter.selectId(entity);
        if (id in state.entities) {
            continue;
        }

        ids.push(id);
        entities[id] = entity;
    }

    state = {
        ...state,
        ids,
        entities
    };

    return adapter.sort ? sort(state, adapter) : state;
}

/**
 * Adds one entity to the state if it does not contain it already.
 */
export function provideAddOne<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T
) {
    return provideAddMany(state, adapter, [data]);
}

/**
 * Updates the entities in the state if they are found.
 */
export function provideUpdateMany<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T[]
) {
    let entities = {...state.entities};
    for (let entity of data) {
        const id = adapter.selectId(entity);
        if (id in entities) {
            entities[id] = adapter.updateFullEntity ? entity : {...entities[id], ...entity};
        }
    }

    state = {
        ...state,
        entities
    };

    return adapter.sort ? sort(state, adapter) : state;
}

/**
 * Updates the entity in the state if it is found.
 */
export function provideUpdateOne<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T
) {
    return provideUpdateMany(state, adapter, [data]);
}

/**
 * Upserts (update or insert if not found) the entities in the state.
 */
export function provideUpsertMany<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T[]
) {
    const added: T[] = [];
    const updated: T[] = [];
    let clonedState = {...state};

    for (let entity of data) {
        const id = adapter.selectId(entity);
        if (id in state.entities) {
            updated.push(entity);
        } else {
            added.push(entity);
        }
    }

    clonedState = provideUpdateMany(clonedState, adapter, updated);
    clonedState = provideAddMany(clonedState, adapter, added);

    return clonedState;
}

/**
 * Upserts (update or insert if not found) an entity in the state.
 */
export function provideUpsertOne<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T
) {
    return provideUpsertMany(state, adapter, [data]);
}

/**
 * Remove entities from the state if it contains them.
 */
export function provideRemoveMany<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T[]
) {
    const keys = [];
    let ids = [...state.ids];
    let entities = {...state.entities};

    for (let entity of data) {
        keys.push(adapter.selectId(entity));
    }

    keys
        .filter(key => key in entities)
        .map(key => delete entities[key])
    ;
    ids = ids.filter(id => id in entities);

    state = {
        ...state,
        ids,
        entities
    };

    return adapter.sort ? sort(state, adapter) : state;
}

/**
 * Remove an entity from the state if it contains it.
 */
export function provideRemoveOne<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data: T
) {
    return provideRemoveMany(state, adapter, [data]);
}

/**
 * Clears all entities from the state.
 */
export function provideRemoveAll<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>
) {
    return {
        ...state,
        ids: [],
        entities: {}
    };
}

/**
 * Select the current entity in the state.
 */
export function provideSetSelected<T, S extends EntityState<T>>(
    state: S, adapter: EntityAdapter<T, S>, data?: T
) {
    if (undefined === data) {
        return {
            ...state,
            selectedId: undefined,
        };
    }

    const id = adapter.selectId(data);
    if (id in state.entities) {
        return {
            ...state,
            selectedId: id,
        };
    }

    return state;
}

function sort<T, S extends EntityState<T>>(state: S, adapter: EntityAdapter<T, S>): S {
    let ids: IdType[] = [];
    let entities = Object.values(state.entities).sort(adapter.sort);
    for (let entity of entities) {
        ids.push(adapter.selectId(entity));
    }

    return {
        ...state,
        ids
    };
}