import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from '@ui/material';
import {PipesModule} from '@ui/pipes';

import {CommentFormComponent} from './components/comment-form/comment-form.component';
import {DiscussionComponent} from './components/discussion/discussion.component';
import {DiscussionListComponent} from './components/discussion-list/discussion-list.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        TranslateModule.forChild(),
    ],
    declarations: [DiscussionListComponent, DiscussionComponent, CommentFormComponent],
    exports: [DiscussionListComponent]
})
export class DiscussionUiModule {}
