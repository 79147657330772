import {Injectable, OnDestroy} from '@angular/core';
import {IdType} from '@axiocode/entity';
import {Branch, BranchStore} from '@branch/data';
import {User, UserStore} from '@user/data';
import {exhaustiveMatchGuard} from '@utils';
import {SubSink} from 'subsink';

import {isSuperAdmin} from '../utils/roles';

/**
 * Permissions list. These permissions will be prefixed with the corresponding domain target.
 * IE: "write" => "Organization/Write".
 */
export type PermissionAction = 'read' | 'write' | 'comment';
export type PermissionType = Permission['type'];
export type PermissionTarget = Permission['target'];
type LookUpType<U, T> = U extends {type: T} ? U : never;
type LookUpTarget<U, T> = U extends {target: T} ? U : never;
export type PermissionTargetByType<T extends PermissionType> = LookUpType<Permission, T>['target'];
export type PermissionActionsByType<T extends PermissionType> = LookUpType<Permission, T>['permission'];
export type PermissionTypeByTarget<T extends PermissionTarget> = LookUpTarget<Permission, T>['type'];
export type InformationSystemType = {
    id: IdType;
    organization: OrganizationType;
};
export type OrganizationType = {
    id: IdType;
};
export type Permission = {
    type: 'is',
    target: InformationSystemType,
    permission: PermissionAction,
    checkBranch?: boolean
} | {
    type: 'organization',
    target: OrganizationType,
    permission: PermissionAction
};

@Injectable({
    providedIn: 'root',
})
export class PermissionService implements OnDestroy {
    private currentUser?: User = undefined;
    private currentBranch?: Branch = undefined;
    private subs = new SubSink();

    /** @ignore */
    constructor(private store: UserStore, private branchStore: BranchStore) {
        this.subs.sink = this.store.selectCurrentUser$.subscribe(user => (this.currentUser = user));
        this.subs.sink = this.branchStore.selectCurrentBranch$.subscribe(branch => (this.currentBranch = branch));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /**
     * Checks a permission on a target.
     * The "read" permission is also granted if the user has the "write" permission on the target.
     * The permission is granted on an Application if the user has the permission on the application's Organization.
     */
    public isGranted(permission: Permission): boolean {
        if (undefined === this.currentUser) {
            return false;
        }
        // SuperAdmin is all mighty. Long live SuperAdmin!
        if (this.isSuperAdmin()) {
            return true;
        }
        const type = permission.type;
        switch (type) {
            case 'is':
                return this.checkPermissionOnIS(permission.permission, permission.target, false === permission.checkBranch ? false : true);
            case 'organization':
                return this.checkPermissionOnOrganization(permission.permission, permission.target);
            default:
                return exhaustiveMatchGuard(type);
        }
    }

    public isSuperAdmin(): boolean {
        const roles = this.currentUser?.roles;

        return !!(roles && isSuperAdmin(roles));
    }

    private checkPermissionOnIS(permission: PermissionAction, target: InformationSystemType, checkBranch: boolean): boolean {
        // If the asked permission is 'write' but the current branch is the main branch of the IS,
        // we must deny the permission. SuperAdmin role should have been checked before this.
        if (checkBranch && 'write' === permission && this.currentBranch?.isMainBranch) {
            return false;
        }

        const permissionName = 'InformationSystem/' + permission.charAt(0).toUpperCase() + permission.slice(1);
        const permissionFound = this.currentUser?.accountPermissions?.find(value => {
            if (value.informationSystem?.id !== target.id) {
                return false;
            }

            // check permission + the "comment" and "write" permission (since if we can comment or write, we can also read)
            return (
                permissionName === value.permission?.name ||
                ('read' === permission && 'InformationSystem/Comment' === value.permission?.name) ||
                (('read' === permission || 'comment' === permission) && 'InformationSystem/Write' === value.permission?.name)
            );
        });

        if (undefined !== permissionFound) {
            return true;
        }

        // Also check the permission on the InformationSystem's organization
        if (target.organization) {
            return this.checkPermissionOnOrganization(permission, target.organization);
        }

        return false;
    }

    private checkPermissionOnOrganization(permission: PermissionAction, target: OrganizationType): boolean {
        const permissionName = 'Organization/' + permission.charAt(0).toUpperCase() + permission.slice(1);
        const permissionFound = this.currentUser?.accountPermissions?.find(value => {
            if (value.organization?.id !== target.id) {
                return false;
            }

            // check permission + the "comment" and "write" permission (since if we can comment or write, we can also read)
            return (
                permissionName === value.permission?.name ||
                ('read' === permission && 'Organization/Comment' === value.permission?.name) ||
                (('read' === permission || 'comment' === permission) && 'Organization/Write' === value.permission?.name)
            );
        });

        return undefined !== permissionFound;
    }
}
