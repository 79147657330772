import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';
import {PermissionService} from '@user/security';

import {DiscussionProvider} from './services/discussion.provider';
import {DiscussionStore, initializeISLoader} from './services/discussion.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class DiscussionDataModule {
    constructor(
        loader: DataLoaderService,
        provider: DiscussionProvider,
        store: DiscussionStore,
        permissionService: PermissionService
    ) {
        initializeISLoader(loader, provider, store, permissionService)();
    }
}
