import {Pipe, PipeTransform} from '@angular/core';
import {removeAccents, removeRegexSymbols, wrapAt} from '@utils';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

    transform(value: unknown, query: string): string {
        if ('string' !== typeof value) {
            return '';
        }

        // First match is strict match
        return this.matchAndReplace(query, value);
    }

    private matchAndReplace(query: string, value: string): string {
        /**
         * In order to take the accents into account we:
         *   - replace accents by the base character in both query and value
         *   - match modified value with the query and find out the positions of matches
         *   - wrap the results (by positions) in the ORIGINAL value inside the <span> tag
         * We need to keep in mind that each time we insert tags we move the start and end position of all the next
         * matches in the original string.
         */
        try {
            query = removeRegexSymbols(query);
            query = removeAccents(query);
            const modifiedValue = removeAccents(value);
            const regex = new RegExp(query, 'igu');
            const matches = modifiedValue.matchAll(regex);

            if (!matches) {
                return value;
            }

            const before = '<span class="highlight">';
            const after = '</span>';
            const tagLength = (before + after).length;
            let addedLength = 0;
            for (const match of matches) {
                if (undefined !== match.index) {
                    value = wrapAt(
                        value,
                        before,
                        after,
                        addedLength + match.index,
                        addedLength + match.index + match[0].length
                    );
                    addedLength += tagLength;
                }
            }
        } catch (error: any) {
            // The user has entered invalid characters or sequence of characters.
        }

        return value;
    }

}
