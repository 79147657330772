import {AccountPermissionDataModule} from '@account-permission/data';
import {ActorDataModule} from '@actor/data';
import {ActorUiModule} from '@actor/ui';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AppComponentDataModule} from '@app-component/data';
import {AxiocodeErrorHandlerModule} from '@axiocode/error-handler';
import {BranchDataModule} from '@branch/data';
import {ConfigurationModule} from '@configuration';
import {DataModelDataModule} from '@data-model/data';
import {DataModelUiModule} from '@data-model/ui';
import {DiscussionDataModule} from '@discussion/data';
import {FeatureDataModule} from '@feature/data';
import {FormDataModule} from '@form/data';
import {FunctionalRequirementDataModule} from '@functional-requirement/data';
import {GlossaryTermDataModule} from '@glossary-term/data';
import {GlossaryTermUiModule} from '@glossary-term/ui';
import {InformationSystemDataModule} from '@information-system/data';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NonFunctionalRequirementDataModule} from '@non-functional-requirement/data';
import {PageDataModule} from '@page/data';
import {SeoModule} from '@seo';
import {TableDataModule} from '@table/data';
import {ApiToken, ProjectConfigToken} from '@token';
import {BreadcrumbModule} from '@ui/breadcrumb';
import {DrawerModule} from '@ui/drawer';
import {HotkeysModule} from '@ui/hotkeys';
import {LoaderModule} from '@ui/loader';
import {MaterialModule} from '@ui/material';
import {NavigationPlanModule} from '@ui/navigation-plan';
import {NotificationModule} from '@ui/notification';
import {SidebarModule} from '@ui/sidebar';
import {ToolbarModule} from '@ui/toolbar';
import {UseCaseDataModule} from '@use-case/data';
import {AuthModule} from '@user/auth';
import {UserDataModule} from '@user/data';
import {VersionableDataModule} from '@versionable/data';

import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';
import {NotFoundComponent} from './not-found.component';
import {environment} from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + environment.release);
}

@NgModule({
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AxiocodeErrorHandlerModule.forRoot({debug: !environment.production, ignoreErrorCodes: [422]}),
        TranslateModule.forRoot({
            defaultLanguage: window.localStorage.getItem('lang') || 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot(appRoutes, {
            paramsInheritanceStrategy: 'always',
            bindToComponentInputs: true,
        }),
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),

        // Managician modules
        AuthModule.forRoot({
            loginUrl: '/auth/login',
            authServiceBaseUrl: environment.apiUrl,
            apiBaseUrl: environment.apiUrl,
            redirectAfterLogin: '/application/selection',
            alwaysRememberMe: true,
            enableGoogleAuth: true,
            googleClientId: environment.googleClientId,
        }),

        // Data modules
        ConfigurationModule, // We need to load this module for the whole application
        InformationSystemDataModule, // We need to load this module for the whole application
        BranchDataModule, // We need to load this module for the whole application
        ActorDataModule, // We need to load this module for the DataLoaderService
        AppComponentDataModule, // We need to load this module for the DataLoaderService
        DataModelDataModule, // We need to load this module for the DataLoaderService
        DiscussionDataModule, // We need to load this module for the DataLoaderService
        FeatureDataModule, // We need to load this module for the DataLoaderService
        FormDataModule, // We need to load this module for the DataLoaderService
        FunctionalRequirementDataModule, // We need to load this module for the DataLoaderService
        GlossaryTermDataModule, // We need to load this module for the DataLoaderService
        NonFunctionalRequirementDataModule, // We need to load this module for the DataLoaderService
        PageDataModule, // We need to load this module for the DataLoaderService
        TableDataModule, // We need to load this module for the DataLoaderService
        UseCaseDataModule, // We need to load this module for the DataLoaderService
        VersionableDataModule, // We need to load this module for the DataLoaderService
        AccountPermissionDataModule,

        // UI modules
        ActorUiModule, // We need to load this module for the quick menu
        DataModelUiModule, // We need to load this module for the quick menu
        GlossaryTermUiModule, // We need to load this module for the quick menu

        SeoModule,
        BreadcrumbModule,
        UserDataModule,
        DrawerModule,
        HotkeysModule,
        LoaderModule,
        MaterialModule,
        NotificationModule,
        ToolbarModule,
        SidebarModule,

        // Environment specific
        environment.optionalModules,
        NavigationPlanModule,
    ],
    providers: [
        {
            provide: ApiToken,
            useValue: {apiBaseUrl: environment.apiUrl, ttl: environment.httpCacheTTL},
        },
        {
            provide: ProjectConfigToken,
            useValue: {release: environment.release},
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
