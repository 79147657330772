import {Component, Input, Output} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import {Subject} from 'rxjs';

@Component({
    selector: 'ui-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {

    @Input() buttonType: 'normal' | 'raised' | 'stroked' | 'icon' | 'menu-item' = 'icon';
    @Input() cy = '';
    @Input() color = '';
    @Input() icon = '';
    @Input() disabled = false;
    @Input() tooltip?: string = undefined;
    @Input() tooltipPosition: TooltipPosition = 'above';
    @Input() tooltipClass = '';
    @Input() routerLink: any[] | string | null | undefined = undefined;

    /** @deprecated use (click)="..." instead */
    @Output() clicked: Subject<undefined> = new Subject<undefined>();

    /** @deprecated */
    onClick(): void {
        this.clicked.next(undefined);
    }

}
