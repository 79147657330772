import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from '@ui/material';

import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule.forChild(),
        RouterModule,
    ],
    declarations: [BreadcrumbComponent],
    exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
