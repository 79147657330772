import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, OnDestroy, input} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOption, MatSelect} from '@angular/material/select';
import {IdType} from '@axiocode/entity';
import {Branch, BranchStore, BranchSwitcherService} from '@branch/data';
import {InformationSystemStore} from '@information-system/data';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutes} from '@routing';
import {DrawerService} from '@ui/drawer';
import {modelsEqualityById} from '@utils';
import {filter, map, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

@Component({
    selector: 'sidebar-branch-switcher',
    standalone: true,
    template: `
    <div class="row">
        <div class="switcher-select" *ngIf="filteredBranches$ | async as branches">
            <mat-form-field>
                <mat-label>{{ 'BRANCH.BRANCH' | translate }}</mat-label>
                <mat-select [compareWith]="compare" [value]="currentBranch()" (valueChange)="changeBranch($event)">
                    <mat-option
                        *ngFor="let branch of branches"
                        (click)="changeBranch(branch)"
                        [value]="branch">
                        @if (branch.isMainBranch) {
                            <strong>{{ 'BRANCH.MAIN_BRANCH' | translate }}</strong>
                        } @else {
                            {{ branch.name }}
                        }
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatFormFieldModule,
        MatSelect,
        MatOption,
        TranslateModule,
        CommonModule,
        TranslateModule
    ],
})
export class BranchSwitcherComponent implements OnDestroy {
    currentBranch = input<Branch>();

    filteredBranches$ = this.store.selectNavigableBranches$.pipe(
        map(branches => branches.sort((a, b) => a.name.localeCompare(b.name))),
    );

    compare = modelsEqualityById;

    #subs = new SubSink();

    constructor(
        public routes: AppRoutes,
        private store: BranchStore,
        private isStore: InformationSystemStore,
        private branchSwitcher: BranchSwitcherService,
        private drawerService: DrawerService,
    ) {
        // wait until the IS is loaded before loading branches, otherwise we'll get an error
        this.#subs.sink = this.isStore.selectSelectedEntity$.pipe(
            filter(is => !!is),
            take(1),
            tap(() => this.store.findAll())
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    changeBranch(branch: {id: IdType}): void {
        this.drawerService.closeDrawer().then(() => this.branchSwitcher.switchBranch(branch as Branch));
    }
}
