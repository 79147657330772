import {NgModule} from '@angular/core';
import {DataLoaderService} from '@information-system/data';

import {ConfigurationProvider} from './services/configuration-provider.service';
import {ConfigurationStore, initializeDataLoader} from './services/configuration.store';
import {UserPreferencesService} from './services/user-preferences.service';

@NgModule({
    providers: [
        UserPreferencesService,
    ]
})
export class ConfigurationModule {
    constructor(loader: DataLoaderService, provider: ConfigurationProvider, store: ConfigurationStore) {
        initializeDataLoader(loader, provider, store)();
    }
}
