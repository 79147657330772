import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';

import {AuthService} from '../services/auth.service';
import {AuthStore} from '../services/auth.store';
import {AuthModuleConfig, AuthModuleToken} from '../token';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    /** @ignore */
    constructor(
        @Inject(AuthModuleToken) private config: AuthModuleConfig,
        private store: AuthStore,
        private cookies: CookieService,
        private authService: AuthService // eslint-disable-next-line no-empty-function
    ) {}

    /** @ignore */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.startsWith(this.config.apiBaseUrl) && 0 > request.url.indexOf('refresh')) {
            const refreshToken = this.cookies.get('refresh_token');
            if (!refreshToken) {
                // This code is disabled because the API does not allow to refresh the token
                // if (refreshToken) {
                //     return this.authService.refreshToken(refreshToken).pipe(
                //         mergeMap(() => next.handle(request)),
                //         catchError((error: HttpErrorResponse) => {
                //             this.store.logout();

                //             return next.handle(request);
                //         })
                //     );
                // }

                // Temp fix 07/2024 reset password bug
                // this.store.logout();
            }
        }

        return next.handle(request);
    }
}
