import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';

export abstract class BaseFormType<T extends { [key: string]: any; }> {

    /** @ignore */
    protected form?: FormGroup = undefined;

    /** @ignore */
    protected initialValue?: T = undefined;

    /** @ignore */
    protected options: {nameMaxLength: number};

    /**
     * @param options Options you want to access in your custom form type.
     */
    public constructor(options: {nameMaxLength: number} = {nameMaxLength: 100}) {
        this.options = options;
    }

    /**
     * Implement this method to create a FormGroup representing the form and its controls.
     * The FormGroup can nest other FormGroups or FormArrays.
     *
     * @returns FormGroup containing the form controls (fields).
     */
    protected abstract build(): FormGroup;

    /**
     * You can use this protected method in getValue() to get the form raw values and construct the model object
     * to return.
     *
     * @returns Returns the form value.
     */
    public getValue(): T | undefined {
        if (this.form) {
            return (this.initialValue ? {...this.initialValue, ...this.form.getRawValue()} : this.form.getRawValue()) as T;
        }

        return undefined;
    }

    /**
     * Returns a FormGroup containing the form controls (fields).
     * It can nest other FormGroups or FormArrays.
     *
     * @param data The initial data to bind to the form. @see bind().
     * @returns FormGroup containing the form controls (fields).
     */
    public getForm(data?: T): UntypedFormGroup {
        if (!this.form) {
            this.form = this.build();
            if (data) {
                this.bind(data);
            }
        }

        return this.form;
    }

    /**
     * This method can be overridden to set the data in the form controls. By default, it can set the values of a simple
     * FormGroup or simple nested FormGroups. But if your form contains FormArrays, you will need to override bind() and
     * implement the data binding.
     *
     * You must build the form before binding data to it!
     *
     * @param data The initial data to bind to the form.
     */
    public bind(data: T): void {
        if (this.form) {
            this.initialValue = data;
            this.form.patchValue(data);

            return;
        }

        throw new Error('You must build the form before binding data to it!');
    }

    /**
     * This method resets the form with its last bound value. You MUST use bind() before calling reset(), otherwise it
     * won't have any effect.
     */
    public reset(): void {
        if (this.form) {
            if (this.initialValue) {
                this.form.patchValue(this.initialValue);
            }

            return;
        }

        throw new Error('You must build the form before binding data to it!');
    }

    /**
     * @returns Custom options if defined at construction.
     */
    public getOptions(): any {
        return this.options;
    }


    /**
     * Values given to bind() are cached and can be used to construct objects in getValue(), along with getRawValues().
     *
     * @returns The last bound object or undefined if the form has not been defined.
     */
    protected getInitialValue(): T | undefined {
        if (this.form && this.initialValue) {
            return this.initialValue;
        }

        return undefined;
    }

}

/**
 * Allows to create a FormGroup type from any model interface.
 * Does not work with FormArrays, yet.
 *
 * ```typescript
 * const formGroup = new FormGroup<ControlsOf<Actor>>({ ... });
 * ```
 */
export type ControlsOf<T extends Record<string, any>> = {
    [K in keyof T]: T[K] extends Record<any, any>
        ? FormGroup<ControlsOf<T[K]>>
        : FormControl<T[K]>;
};
