import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {DataLoaderService} from '@information-system/data';

import {FormProvider} from './services/form.provider';
import {FormStore, initializeApplicationLoader} from './services/form.store';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class FormDataModule {
    constructor(
        loader: DataLoaderService, provider: FormProvider, store: FormStore,
        @Optional() @SkipSelf() parentModule?: FormDataModule
    ) {
        if (parentModule) {
            throw new Error('FormDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeApplicationLoader(loader, provider, store)();
    }
}
