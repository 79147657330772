import {ChangeDetectionStrategy, Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {modelsEqualityById} from '@utils';
import {BehaviorSubject, Observable, combineLatest, map, startWith} from 'rxjs';

@Component({
    selector: 'form-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent<T extends {id: number | string}> implements OnChanges, OnInit {
    @Input() formGroupParam: any = new UntypedFormGroup({});
    @Input() controlName!: string;
    @Input() compare = modelsEqualityById;
    @Input() data: T[] | null | undefined = [];
    @Input() isNullable = false;
    @Input() getDataId = false;
    @ContentChild('itemLabel') content!: TemplateRef<unknown>;

    searchControl: FormControl = new FormControl<string>('');
    datasFiltered$: Observable<T[]>;

    private dataChanged$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    @Input() filterMethod: (search: string, data: T[]) => T[] = () => [];

    constructor() {
        this.datasFiltered$ = combineLatest(
            [this.dataChanged$, this.searchControl.valueChanges.pipe(startWith(''))]
        ).pipe(
            map(([data, searchValue]) => this.filterMethod(searchValue, data))
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['data']) {
            this.dataChanged$.next(changes['data'].currentValue);
        }
    }

    ngOnInit(): void {
        this.dataChanged$.next(this.data as T[]);
    }

    getDataValue(data: T, isId: boolean = false) {
        if (isId) {
            return data.id;
        }

        return data;
    }
}
