import {Inject, Injectable, OnDestroy} from '@angular/core';
import {EntityProvider, IdType} from '@axiocode/entity';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {ApiConfig, ApiToken} from '@token';
import {Observable, Subscription, distinctUntilChanged, tap} from 'rxjs';

import {DependencyLink} from '../models/requirement.model';

@Injectable({providedIn: 'root'})
export class DependencyProvider extends EntityProvider<DependencyLink> implements OnDestroy {
    /** @ignore */
    private informationSystem: InformationSystem | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<DependencyLink[]> {
        if (undefined === this.informationSystem) {
            throw new Error('Current information system is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystem/${this.informationSystem.id}/requirementdependencies`);
    }
    override findOne$(id: IdType): Observable<DependencyLink> {
        throw new Error('Method not implemented.');
    }
    override create$(data: Partial<DependencyLink>): Observable<DependencyLink> {
        return this._create$(`${this.config.apiBaseUrl}/api/add/requirementdependency`, data);
    }
    override update$(data: Partial<DependencyLink>, method: 'patch' | 'put'): Observable<DependencyLink> {
        throw new Error('Method not implemented.');
    }
    override delete$(data: DependencyLink): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/requirementdependency/${data.id}`);
    }

    constructor(@Inject(ApiToken) private config: ApiConfig, ISStore: InformationSystemStore) {
        super();

        this.subscription = ISStore.selectSelectedEntity$.pipe(
            // Filter out if the IS is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current IS
            tap(() => this.resetCache()),
            tap(IS => this.informationSystem = IS),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
