import {OperatorFunction, map} from 'rxjs';

import {PermissionActionsByType, PermissionService, PermissionTargetByType} from '../services/permission.service';

export function keepOnlyOrganizationsWithPermission<T extends PermissionTargetByType<'organization'>>(permission: PermissionActionsByType<'organization'>, service: PermissionService): OperatorFunction<T[], T[]> {
    return map((items: T[]) => items.filter(target => service.isGranted({
        type: 'organization', permission: permission, target: target
    })));
}

export function keepOnlyInformationSystemWithPermission<T extends PermissionTargetByType<'is'>>(permission: PermissionActionsByType<'is'>, service: PermissionService): OperatorFunction<T[], T[]> {
    return map((items: T[]) => items.filter(target => service.isGranted({
        type: 'is', permission: permission, target: target
    })));
}
