import {Inject, Injectable, OnDestroy} from '@angular/core';
import {EntityProvider, IdType} from '@axiocode/entity';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {ApiConfig, ApiToken} from '@token';
import {Observable, Subscription, distinctUntilChanged, tap} from 'rxjs';

import {PageItem} from '../models/page-item.model';
import {Page} from '../models/page.model';

@Injectable({providedIn: 'root'})
export class PageProvider extends EntityProvider<Page> implements OnDestroy {
    /** @ignore */
    private IS: InformationSystem | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<Page[]> {
        if (undefined === this.IS) {
            throw new Error('Current information system is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/informationsystem/${this.IS.id}/nonfunctionalrequirementpages`);
    }

    override findOne$(id: IdType): Observable<Page> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/nonfunctionalrequirementpage/${id}`);
    }

    override create$(data: Partial<Page>): Observable<Page> {
        return this._create$(`${this.config.apiBaseUrl}/api/nonfunctionalrequirementpage`, data);
    }

    override update$(data: Partial<Page>, method: 'patch' | 'put'): Observable<Page> {
        const items = [...data.nonFunctionalRequirementPageItems || []];
        data.nonFunctionalRequirementPageItems = items.map(item => ({
            id: item.id,
            orderPosition: item.orderPosition || 0,
            dataModel: item.dataModel ? {id: item.dataModel.id} : undefined,
            dataTable: item.dataTable ? {id: item.dataTable.id} : undefined,
            form: item.form ? {id: item.form.id} : undefined,
            itemDataModelAttributes: item.itemDataModelAttributes || [],
        } as PageItem));

        return this._update$(`${this.config.apiBaseUrl}/api/nonfunctionalrequirementpage/${data.id}`, data, method);
    }

    override delete$(data: Page): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/nonfunctionalrequirementpage/${data.id}`);
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        ISStore: InformationSystemStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = ISStore.selectSelectedEntity$.pipe(
            // Filter out if the IS is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current IS
            tap(() => this.resetCache()),
            tap(IS => this.IS = IS),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
