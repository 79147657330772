<form [formGroup]="form">
    <div class="row">
        <div class="col small">
            <mat-form-field>
                <mat-label>{{ 'COMMON.CODE' | translate }}</mat-label>
                <input type="number" matInput required formControlName="code" data-cy="datamodel-code-input">
                <span matTextPrefix>{{ prefix }}&nbsp;</span>
                <mat-error *ngIf="form.get('code')?.hasError('required')">{{ 'ERROR.INPUT_NOT_COMPLETE' | translate
                    }}</mat-error>
                <mat-error *ngIf="form.get('code')?.hasError('api')">{{ form.get('code')?.getError('api')
                    }}</mat-error>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>{{ 'ACTOR.ACTOR_NAME' | translate }}</mat-label>
                <input [maxlength]="formType.getOptions().nameMaxLength" matInput required formControlName="name"
                    data-cy="actor-name-input">
                <mat-error *ngIf="form.get('name')?.hasError('required')" data-cy="actor-name-error">
                    {{ 'ERROR.INPUT_NOT_COMPLETE' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('name')?.hasError('maxlength')" data-cy="actor-name-error">
                    {{ 'ERROR.INPUT_MAX_LENGTH' | translate: {'max':
                    form.get('name')?.getError('maxlength')?.requiredLength} }}
                </mat-error>
                <mat-error *ngIf="form.get('name')?.hasError('api')" data-cy="actor-name-error">
                    {{ form.get('name')?.getError('api') }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col small">
            <mat-checkbox
                formControlName="isRole" data-cy="actor-role-checkbox">{{ 'ACTOR.ROLE' | translate }}</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form-select-search
                [data]="inheritableActors"
                [controlName]="'inherits'"
                [compare]="compare"
                [isNullable]="true"
                [formGroupParam]="form"
                [filterMethod]="filter">
                <ng-template #itemLabel let-data="data">{{ data.name }}</ng-template>
                <ng-container label>
                    {{ 'ACTOR.ACTOR_INHERITS' | translate }}
                </ng-container>
                <ng-container error>
                    <div *ngIf="form.get('inherits')?.hasError('api')">
                        {{ form.get('inherits')?.getError('api') }}
                    </div>
                </ng-container>
            </form-select-search>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-label class="ckeditor-label">{{ 'COMMON.DESCRIPTION' | translate }}</mat-label>
            <ckeditor-mentions [currentRequirement]="actor" formControlName="description"
                data-cy="actor-description-input"></ckeditor-mentions>
            <mat-error *ngIf="form.get('description')?.hasError('api')" data-cy="actor-description-error">
                {{ form.get('description')?.getError('api') }}
            </mat-error>
        </div>
    </div>
</form>