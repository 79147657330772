import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {AuthStore} from '@user/auth';

export interface ToolbarLocalState {
    isSearchOpened: boolean;
}

const defaultState: ToolbarLocalState = {
    isSearchOpened: false,
};

@Injectable()
export class ToolbarLocalStore extends ComponentStore<ToolbarLocalState> {
    // Selectors
    readonly selectIsLoggedIn$ = this.authStore.selectIsLoggedIn$;
    readonly selectIsSearchOpened$ = this.select(state => state.isSearchOpened);

    // Updaters
    readonly setIsSearchOpened = this.updater((state, isSearchOpened: boolean) => ({
        ...state,
        isSearchOpened
    }));

    constructor(
        private authStore: AuthStore,
    ) {
        super(defaultState);
    }
}
