import {inject, Injectable} from '@angular/core';
import {Branch, BranchSwitcherService} from '@branch/data';
import {DialogHandle, DialogService} from '@ui/dialog';
import {SubSink} from 'subsink';

import {BranchDialogComponent} from '../branch-dialog/branch-dialog.component';

@Injectable()
export class BranchCreateActionService {
    #dialogService = inject(DialogService);
    #branchSwitcher = inject(BranchSwitcherService);
    #subs = new SubSink();
    #dialog?: DialogHandle = undefined;

    openDialogCreateBranch(editBranch?: Branch): void {
        this.closeDialog();
        this.#dialog = this.#dialogService.open(BranchDialogComponent, {
            title: editBranch ? 'BRANCH.EDIT' : 'BRANCH.ADD',
            data: editBranch,
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAccepted.subscribe((value: Branch) => {
            this.closeDialog();
            this.#branchSwitcher.switchBranch(value);
        });
    }

    closeDialog(): void {
        this.#dialog?.close();
        this.#subs.unsubscribe();
    }
}
